
/* -------------- SITE SYSTEM 6 THEME  Copyright 2024 Sinclair Design --------------- */
/* -------------------              colours.scss                 ------------------- */
/* ---------------------------------------------------------------------------------- */
/* --------------------------- CUSTOM SCSS FOR CLIENT ------------------------------- */
/* ---------------------------------------------------------------------------------- */

/*  set the brand1, 2 and 3 colours used for the customer's branding/identity system */
$brand1:        #4db45b; // green
$brand2:        #5e739c; // blue
$brand3:        #cec046; // yellow
$white:         #fff;
$black:         #000;
$cream:         #f9f6dc; // used for the store and home header

/*  set the mixes for the different shades and borders  */
$mix-light:     55%; // amount of white to add
$mix-lighter:   78%; // amount of white to add
$mix-v-light:   85%; // amount of white to add
$mix-dark:      30%; // amount of black to add (used for headline text)
$mix-darker:    50%; // amount of black to add (used for body copy)
$mix-border:    48%; // amount of white to add
$desat-border:  30%; // amount to desaturate the borders

/*  set the various shades of the base colours  */
$brand1-light: mix($white, $brand1, $mix-light);
$brand1-lighter: mix($white, $brand1, 73%);
$brand1-very-light: mix($white, $brand1, $mix-v-light);
$brand1-dark: mix($black, $brand1, $mix-dark);
$brand1-darker: mix($black, $brand1, $mix-darker);
$brand1-border: mix($white, $brand1, $mix-border);
$brand1-border: desaturate($brand1-border, $desat-border);

$brand2-light: mix($white, $brand2, $mix-light);
$brand2-lighter: mix($white, $brand2, $mix-lighter);
$brand2-very-light: mix($white, $brand2, $mix-v-light);
$brand2-dark: mix($black, $brand2, $mix-dark);
$brand2-darker: mix($black, $brand2, $mix-darker);
$brand2-border: mix($white, $brand2, $mix-border);
$brand2-border: desaturate($brand2-border, $desat-border);

$brand3-light: mix($white, $brand3, $mix-light);
$brand3-lighter: mix($white, $brand3, $mix-lighter);
$brand3-very-light: mix($white, $brand3, $mix-v-light);
$brand3-dark: mix($black, $brand3, $mix-dark);
$brand3-darker: mix($black, $brand3, $mix-darker);
$brand3-border: mix($white, $brand3, $mix-border);
$brand3-border: desaturate($brand3-border, $desat-border);