/* -------------- SITE SYSTEM 6 THEME  Copyright 2024 Sinclair Design --------------- */
/* -------------------                  main.scss                 ------------------- */
/* ---------------------------------------------------------------------------------- */
/* ------------------ THE FINAL COLLECTION OF SCSS IMPORTS (ONLY) ------------------- */
/* ---------------------------------------------------------------------------------- */
/* -------------- SITE SYSTEM 6 THEME  Copyright 2024 Sinclair Design --------------- */
/* -------------------              _variables.scss               ------------------- */
/* ---------------------------------------------------------------------------------- */
/* ----------------------------- CUSTOM SCSS FOR CLIENT ----------------------------- */
/* ---------------------------------------------------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@300;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@1,300;1,500&display=swap");
.navbar-brand {
  left: 0;
  right: 0;
  margin: auto; }

/*$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
) !default; */
/*$colors: (
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "black":      $black,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
) !default; */
/*$blues: (
  "blue-100": $blue-100,
  "blue-200": $blue-200,
  "blue-300": $blue-300,
  "blue-400": $blue-400,
  "blue-500": $blue-500,
  "blue-600": $blue-600,
  "blue-700": $blue-700,
  "blue-800": $blue-800,
  "blue-900": $blue-900
) !default; */
/*$indigos: (
  "indigo-100": $indigo-100,
  "indigo-200": $indigo-200,
  "indigo-300": $indigo-300,
  "indigo-400": $indigo-400,
  "indigo-500": $indigo-500,
  "indigo-600": $indigo-600,
  "indigo-700": $indigo-700,
  "indigo-800": $indigo-800,
  "indigo-900": $indigo-900
) !default; */
/*$purples: (
  "purple-100": $purple-100,
  "purple-200": $purple-200,
  "purple-300": $purple-300,
  "purple-400": $purple-400,
  "purple-500": $purple-500,
  "purple-600": $purple-600,
  "purple-700": $purple-700,
  "purple-800": $purple-800,
  "purple-900": $purple-900
) !default; */
/*$pinks: (
  "pink-100": $pink-100,
  "pink-200": $pink-200,
  "pink-300": $pink-300,
  "pink-400": $pink-400,
  "pink-500": $pink-500,
  "pink-600": $pink-600,
  "pink-700": $pink-700,
  "pink-800": $pink-800,
  "pink-900": $pink-900
) !default; */
/*$reds: (
  "red-100": $red-100,
  "red-200": $red-200,
  "red-300": $red-300,
  "red-400": $red-400,
  "red-500": $red-500,
  "red-600": $red-600,
  "red-700": $red-700,
  "red-800": $red-800,
  "red-900": $red-900
) !default; */
/*$oranges: (
  "orange-100": $orange-100,
  "orange-200": $orange-200,
  "orange-300": $orange-300,
  "orange-400": $orange-400,
  "orange-500": $orange-500,
  "orange-600": $orange-600,
  "orange-700": $orange-700,
  "orange-800": $orange-800,
  "orange-900": $orange-900
) !default; */
/*$yellows: (
  "yellow-100": $yellow-100,
  "yellow-200": $yellow-200,
  "yellow-300": $yellow-300,
  "yellow-400": $yellow-400,
  "yellow-500": $yellow-500,
  "yellow-600": $yellow-600,
  "yellow-700": $yellow-700,
  "yellow-800": $yellow-800,
  "yellow-900": $yellow-900
) !default; */
/*$greens: (
  "green-100": $green-100,
  "green-200": $green-200,
  "green-300": $green-300,
  "green-400": $green-400,
  "green-500": $green-500,
  "green-600": $green-600,
  "green-700": $green-700,
  "green-800": $green-800,
  "green-900": $green-900
) !default; */
/*$teals: (
  "teal-100": $teal-100,
  "teal-200": $teal-200,
  "teal-300": $teal-300,
  "teal-400": $teal-400,
  "teal-500": $teal-500,
  "teal-600": $teal-600,
  "teal-700": $teal-700,
  "teal-800": $teal-800,
  "teal-900": $teal-900
) !default; */
/*$cyans: (
  "cyan-100": $cyan-100,
  "cyan-200": $cyan-200,
  "cyan-300": $cyan-300,
  "cyan-400": $cyan-400,
  "cyan-500": $cyan-500,
  "cyan-600": $cyan-600,
  "cyan-700": $cyan-700,
  "cyan-800": $cyan-800,
  "cyan-900": $cyan-900
) !default; */
/*$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
) !default; */
/*$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
  ("(", "%28"),
  (")", "%29"),
) !default; */
/*$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default; */
/*$position-values: (
  0: 0,
  50: 50%,
  100: 100%
) !default; */
/*$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default; */
/*$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default; */
/*$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
) !default; */
/*$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
) !default; */
/*$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size
) !default; */
/*$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem
) !default; */
/*$table-variants: (
  "primary":    shift-color($primary, $table-bg-scale),
  "secondary":  shift-color($secondary, $table-bg-scale),
  "success":    shift-color($success, $table-bg-scale),
  "info":       shift-color($info, $table-bg-scale),
  "warning":    shift-color($warning, $table-bg-scale),
  "danger":     shift-color($danger, $table-bg-scale),
  "light":      $light,
  "dark":       $dark,
) !default; */
/*$form-validation-states: (
  "valid": (
    "color": $form-feedback-valid-color,
    "icon": $form-feedback-icon-valid
  ),
  "invalid": (
    "color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid
  )
) !default; */
/* -------------- SITE SYSTEM 6 THEME  Copyright 2024 Sinclair Design --------------- */
/* -------------------              colours.scss                 ------------------- */
/* ---------------------------------------------------------------------------------- */
/* --------------------------- CUSTOM SCSS FOR CLIENT ------------------------------- */
/* ---------------------------------------------------------------------------------- */
/*  set the brand1, 2 and 3 colours used for the customer's branding/identity system */
/*  set the mixes for the different shades and borders  */
/*  set the various shades of the base colours  */
/*!
   * Bootstrap  v5.2.3 (https://getbootstrap.com/)
   * Copyright 2011-2022 The Bootstrap Authors
   * Copyright 2011-2022 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #2a5989;
  --bs-secondary: #cdcdcd;
  --bs-success: #3b973b;
  --bs-info: #5e9eb5;
  --bs-warning: #d59337;
  --bs-danger: #b93d39;
  --bs-light: #eeeeee;
  --bs-dark: #212529;
  --bs-primary-rgb: 42, 89, 137;
  --bs-secondary-rgb: 205, 205, 205;
  --bs-success-rgb: 59, 151, 59;
  --bs-info-rgb: 94, 158, 181;
  --bs-warning-rgb: 213, 147, 55;
  --bs-danger-rgb: 185, 61, 57;
  --bs-light-rgb: 238, 238, 238;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 96, 96, 96;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Exo 2, Helvetica, Arial, sans-serif;
  --bs-body-font-size: 1.125rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #606060;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 2px;
  --bs-border-radius-sm: 2px;
  --bs-border-radius-lg: 4px;
  --bs-border-radius-xl: 6px;
  --bs-border-radius-2xl: 10px;
  --bs-border-radius-pill: 50px;
  --bs-link-color: #900;
  --bs-link-hover-color: #ed0202;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #4d4d4d; }

h1, .h1 {
  font-size: calc(1.35rem + 1.2vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.25rem; } }

h2, .h2 {
  font-size: calc(1.31625rem + 0.795vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 1.9125rem; } }

h3, .h3 {
  font-size: calc(1.27688rem + 0.3225vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.51875rem; } }

h4, .h4 {
  font-size: calc(1.26rem + 0.12vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.35rem; } }

h5, .h5 {
  font-size: 1.2375rem; }

h6, .h6 {
  font-size: 1.125rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg); }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: var(--bs-link-color);
  text-decoration: underline; }
  a:hover {
    color: var(--bs-link-hover-color); }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 2px; }
  kbd kbd {
    padding: 0;
    font-size: 1em; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #999;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: calc(1.26563rem + 0.1875vw);
  font-weight: 300; }
  @media (min-width: 1200px) {
    .lead {
      font-size: 1.40625rem; } }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: calc(1.26563rem + 0.1875vw); }
  @media (min-width: 1200px) {
    .blockquote {
      font-size: 1.40625rem; } }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 2px;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.9rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 790px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 1.9rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 790px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color); }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }

.table-group-divider {
  border-top: 2px solid currentcolor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #d4dee7;
  --bs-table-border-color: #bfc8d0;
  --bs-table-striped-bg: #c9d3db;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bfc8d0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c4cdd6;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: whitesmoke;
  --bs-table-border-color: #dddddd;
  --bs-table-striped-bg: #e9e9e9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dddddd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e3e3e3;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d8ead8;
  --bs-table-border-color: #c2d3c2;
  --bs-table-striped-bg: #cddecd;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2d3c2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c8d8c8;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #dfecf0;
  --bs-table-border-color: #c9d4d8;
  --bs-table-striped-bg: #d4e0e4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c9d4d8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cedade;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #f7e9d7;
  --bs-table-border-color: #ded2c2;
  --bs-table-striped-bg: #ebddcc;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #ded2c2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4d8c7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f1d8d7;
  --bs-table-border-color: #d9c2c2;
  --bs-table-striped-bg: #e5cdcc;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d9c2c2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #dfc8c7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #eeeeee;
  --bs-table-border-color: #d6d6d6;
  --bs-table-striped-bg: #e2e2e2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d6d6d6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: gainsboro;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 789.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: calc(1.26563rem + 0.1875vw); }
  @media (min-width: 1200px) {
    .col-form-label-lg {
      font-size: 1.40625rem; } }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.98438rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #999; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #606060;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #606060;
    background-color: #fff;
    border-color: #95acc4;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(42, 89, 137, 0.25); }
  .form-control::-webkit-date-and-time-value {
    height: 1.5em; }
  .form-control::placeholder {
    color: #aaa;
    opacity: 1; }
  .form-control:disabled {
    background-color: #e9ecef;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #606060;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #606060;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext:focus {
    outline: 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.98438rem;
  border-radius: 2px; }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: calc(1.26563rem + 0.1875vw);
  border-radius: 4px; }
  @media (min-width: 1200px) {
    .form-control-lg {
      font-size: 1.40625rem; } }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px); }

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: 2px; }
  .form-control-color::-webkit-color-swatch {
    border-radius: 2px; }
  .form-control-color.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px); }
  .form-control-color.form-control-lg {
    height: calc(1.5em + 1rem + 2px); }

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #606060;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #95acc4;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(42, 89, 137, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #606060; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.98438rem;
  border-radius: 2px; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(1.26563rem + 0.1875vw);
  border-radius: 4px; }
  @media (min-width: 1200px) {
    .form-select-lg {
      font-size: 1.40625rem; } }

.form-check {
  display: block;
  min-height: 1.6875rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right; }
  .form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: 0; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #95acc4;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(42, 89, 137, 0.25); }
  .form-check-input:checked {
    background-color: #2a5989;
    border-color: #2a5989; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #2a5989;
    border-color: #2a5989;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    cursor: default;
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2395acc4'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0; }
    .form-switch.form-check-reverse .form-check-input {
      margin-right: -2.5em;
      margin-left: 0; }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(42, 89, 137, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(42, 89, 137, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #2a5989;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #bfcddc; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #2a5989;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #bfcddc; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem 0.75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext {
    padding: 1rem 0.75rem; }
    .form-floating > .form-control::placeholder,
    .form-floating > .form-control-plaintext::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
    .form-floating > .form-control-plaintext:focus,
    .form-floating > .form-control-plaintext:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill,
    .form-floating > .form-control-plaintext:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control-plaintext ~ label {
    border-width: 1px 0; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-floating {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus,
  .input-group > .form-floating:focus-within {
    z-index: 5; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 5; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #606060;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 2px; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.26563rem + 0.1875vw);
  border-radius: 4px; }
  @media (min-width: 1200px) {
    .input-group-lg > .form-control,
    .input-group-lg > .form-select,
    .input-group-lg > .input-group-text,
    .input-group-lg > .btn {
      font-size: 1.40625rem; } }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.98438rem;
  border-radius: 2px; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #3b973b; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.98438rem;
  color: #000;
  background-color: rgba(59, 151, 59, 0.9);
  border-radius: 2px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #3b973b;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233b973b' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #3b973b;
    box-shadow: 0 0 0 0.25rem rgba(59, 151, 59, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #3b973b; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233b973b' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #3b973b;
    box-shadow: 0 0 0 0.25rem rgba(59, 151, 59, 0.25); }

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #3b973b; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #3b973b; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(59, 151, 59, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #3b973b; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated
.input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid, .was-validated
.input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #b93d39; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.98438rem;
  color: #fff;
  background-color: rgba(185, 61, 57, 0.9);
  border-radius: 2px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #b93d39;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23b93d39'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23b93d39' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #b93d39;
    box-shadow: 0 0 0 0.25rem rgba(185, 61, 57, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #b93d39; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23b93d39'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23b93d39' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #b93d39;
    box-shadow: 0 0 0 0.25rem rgba(185, 61, 57, 0.25); }

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #b93d39; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #b93d39; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(185, 61, 57, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #b93d39; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated
.input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid, .was-validated
.input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4; }

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1.125rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #606060;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 2px;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color); }
  .btn-check + .btn:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color); }
  .btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:focus-visible + .btn {
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:checked + .btn,
  :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color); }
    .btn-check:checked + .btn:focus-visible,
    :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
      box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity); }

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2a5989;
  --bs-btn-border-color: #2a5989;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #193552;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 74, 114, 155;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #22476e;
  --bs-btn-active-border-color: #204367;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2a5989;
  --bs-btn-disabled-border-color: #2a5989; }

.btn-secondary {
  --bs-btn-color: #000;
  --bs-btn-bg: #cdcdcd;
  --bs-btn-border-color: #cdcdcd;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: gainsboro;
  --bs-btn-hover-border-color: #cdcdcd;
  --bs-btn-focus-shadow-rgb: 174, 174, 174;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d7d7d7;
  --bs-btn-active-border-color: #d2d2d2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #cdcdcd;
  --bs-btn-disabled-border-color: #cdcdcd; }

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #3b973b;
  --bs-btn-border-color: #3b973b;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #76b676;
  --bs-btn-hover-border-color: #3b973b;
  --bs-btn-focus-shadow-rgb: 50, 128, 50;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #62ac62;
  --bs-btn-active-border-color: #4fa14f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #3b973b;
  --bs-btn-disabled-border-color: #3b973b; }

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #5e9eb5;
  --bs-btn-border-color: #5e9eb5;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #8ebbcb;
  --bs-btn-hover-border-color: #5e9eb5;
  --bs-btn-focus-shadow-rgb: 80, 134, 154;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #7eb1c4;
  --bs-btn-active-border-color: #6ea8bc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #5e9eb5;
  --bs-btn-disabled-border-color: #5e9eb5; }

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #d59337;
  --bs-btn-border-color: #d59337;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e2b373;
  --bs-btn-hover-border-color: #d59337;
  --bs-btn-focus-shadow-rgb: 181, 125, 47;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #dda95f;
  --bs-btn-active-border-color: #d99e4b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #d59337;
  --bs-btn-disabled-border-color: #d59337; }

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #b93d39;
  --bs-btn-border-color: #b93d39;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6f2522;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 196, 90, 87;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #94312e;
  --bs-btn-active-border-color: #8b2e2b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #b93d39;
  --bs-btn-disabled-border-color: #b93d39; }

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #eeeeee;
  --bs-btn-border-color: #eeeeee;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #8f8f8f;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 202, 202, 202;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #bebebe;
  --bs-btn-active-border-color: #b3b3b3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #eeeeee;
  --bs-btn-disabled-border-color: #eeeeee; }

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #646669;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529; }

.btn-outline-primary {
  --bs-btn-color: #2a5989;
  --bs-btn-border-color: #2a5989;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2a5989;
  --bs-btn-hover-border-color: #2a5989;
  --bs-btn-focus-shadow-rgb: 42, 89, 137;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2a5989;
  --bs-btn-active-border-color: #2a5989;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2a5989;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2a5989;
  --bs-gradient: none; }

.btn-outline-secondary {
  --bs-btn-color: #cdcdcd;
  --bs-btn-border-color: #cdcdcd;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #cdcdcd;
  --bs-btn-hover-border-color: #cdcdcd;
  --bs-btn-focus-shadow-rgb: 205, 205, 205;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #cdcdcd;
  --bs-btn-active-border-color: #cdcdcd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #cdcdcd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #cdcdcd;
  --bs-gradient: none; }

.btn-outline-success {
  --bs-btn-color: #3b973b;
  --bs-btn-border-color: #3b973b;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #3b973b;
  --bs-btn-hover-border-color: #3b973b;
  --bs-btn-focus-shadow-rgb: 59, 151, 59;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3b973b;
  --bs-btn-active-border-color: #3b973b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3b973b;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3b973b;
  --bs-gradient: none; }

.btn-outline-info {
  --bs-btn-color: #5e9eb5;
  --bs-btn-border-color: #5e9eb5;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #5e9eb5;
  --bs-btn-hover-border-color: #5e9eb5;
  --bs-btn-focus-shadow-rgb: 94, 158, 181;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #5e9eb5;
  --bs-btn-active-border-color: #5e9eb5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5e9eb5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5e9eb5;
  --bs-gradient: none; }

.btn-outline-warning {
  --bs-btn-color: #d59337;
  --bs-btn-border-color: #d59337;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d59337;
  --bs-btn-hover-border-color: #d59337;
  --bs-btn-focus-shadow-rgb: 213, 147, 55;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d59337;
  --bs-btn-active-border-color: #d59337;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #d59337;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d59337;
  --bs-gradient: none; }

.btn-outline-danger {
  --bs-btn-color: #b93d39;
  --bs-btn-border-color: #b93d39;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #b93d39;
  --bs-btn-hover-border-color: #b93d39;
  --bs-btn-focus-shadow-rgb: 185, 61, 57;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b93d39;
  --bs-btn-active-border-color: #b93d39;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #b93d39;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #b93d39;
  --bs-gradient: none; }

.btn-outline-light {
  --bs-btn-color: #eeeeee;
  --bs-btn-border-color: #eeeeee;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #eeeeee;
  --bs-btn-hover-border-color: #eeeeee;
  --bs-btn-focus-shadow-rgb: 238, 238, 238;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #eeeeee;
  --bs-btn-active-border-color: #eeeeee;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #eeeeee;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #eeeeee;
  --bs-gradient: none; }

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none; }

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 74, 114, 155;
  text-decoration: underline; }
  .btn-link:focus-visible {
    color: var(--bs-btn-color); }
  .btn-link:hover {
    color: var(--bs-btn-hover-color); }

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: calc(1.26563rem + 0.1875vw);
  --bs-btn-border-radius: 4px; }
  @media (min-width: 1200px) {
    .btn-lg, .btn-group-lg > .btn {
      --bs-btn-font-size: 1.40625rem; } }

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.98438rem;
  --bs-btn-border-radius: 2px; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1.125rem;
  --bs-dropdown-color: #606060;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 2px;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: 1px;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #2a5989;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius); }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer); }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 790px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer); }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer); }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer); }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg); }
  .dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg); }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.98438rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color); }

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #2a5989;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group {
  border-radius: 2px; }
  .btn-group > :not(.btn-check:first-child) + .btn,
  .btn-group > .btn-group:not(:first-child) {
    margin-left: -1px; }
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn.dropdown-toggle-split:first-child,
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .btn-group > .btn:nth-child(n + 3),
  .btn-group > :not(.btn-check) + .btn,
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: var(--bs-nav-link-hover-color); }
  .nav-link.disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 2px;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color); }
  .nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    background: none;
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius); }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: var(--bs-nav-tabs-link-hover-border-color); }
    .nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
      color: var(--bs-nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color); }
  .nav-tabs .dropdown-menu {
    margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills {
  --bs-nav-pills-border-radius: 2px;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #2a5989; }
  .nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: var(--bs-nav-pills-border-radius); }
    .nav-pills .nav-link:disabled {
      color: var(--bs-nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg); }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.28906rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.40625rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.40625rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 2px;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x); }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    color: var(--bs-navbar-brand-hover-color); }

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .show > .nav-link,
  .navbar-nav .nav-link.active {
    color: var(--bs-navbar-active-color); }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color); }
  .navbar-text a,
  .navbar-text a:hover,
  .navbar-text a:focus {
    color: var(--bs-navbar-active-color); }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition); }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-sm .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 790px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-md .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-md .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-lg .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: var(--bs-navbar-nav-link-padding-x);
      padding-left: var(--bs-navbar-nav-link-padding-x); }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none; }
    .navbar-expand .offcanvas .offcanvas-header {
      display: none; }
    .navbar-expand .offcanvas .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; }

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 2px;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: 1px;
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.95rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: var(--bs-card-inner-border-radius);
      border-top-right-radius: var(--bs-card-inner-border-radius); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: var(--bs-card-inner-border-radius);
      border-bottom-left-radius: var(--bs-card-inner-border-radius); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color); }

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y); }

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x); }

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0; }

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-footer:last-child {
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius); }

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0; }
  .card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg); }

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x)); }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius); }

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin); }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion {
  --bs-accordion-color: #606060;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 2px;
  --bs-accordion-inner-border-radius: 1px;
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #606060;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23606060'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2326507b'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #95acc4;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(42, 89, 137, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #26507b;
  --bs-accordion-active-bg: #eaeef3; }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1.125rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition); }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); }
    .accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-active-icon);
      transform: var(--bs-accordion-btn-icon-transform); }
  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition); }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); }
  .accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius); }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: var(--bs-accordion-inner-border-radius);
      border-top-right-radius: var(--bs-accordion-inner-border-radius); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius); }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
      border-bottom-left-radius: var(--bs-accordion-inner-border-radius); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: var(--bs-accordion-border-radius);
      border-bottom-left-radius: var(--bs-accordion-border-radius); }

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x); }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 0; }

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: #e3e3e3;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius); }

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x); }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color); }

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1.125rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 2px;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(42, 89, 137, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #2a5989;
  --bs-pagination-active-border-color: #2a5989;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color); }
  .page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow); }
  .page-link.active,
  .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color); }
  .page-link.disabled,
  .disabled > .page-link {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius); }

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius); }

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: calc(1.26563rem + 0.1875vw);
  --bs-pagination-border-radius: 4px; }
  @media (min-width: 1200px) {
    .pagination-lg {
      --bs-pagination-font-size: 1.40625rem; } }

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.98438rem;
  --bs-pagination-border-radius: 2px; }

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 500;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 2px;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius); }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 2px;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius); }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 500; }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  --bs-alert-color: #193552;
  --bs-alert-bg: #d4dee7;
  --bs-alert-border-color: #bfcddc; }
  .alert-primary .alert-link {
    color: #142a42; }

.alert-secondary {
  --bs-alert-color: #525252;
  --bs-alert-bg: whitesmoke;
  --bs-alert-border-color: #f0f0f0; }
  .alert-secondary .alert-link {
    color: #424242; }

.alert-success {
  --bs-alert-color: #235b23;
  --bs-alert-bg: #d8ead8;
  --bs-alert-border-color: #c4e0c4; }
  .alert-success .alert-link {
    color: #1c491c; }

.alert-info {
  --bs-alert-color: #385f6d;
  --bs-alert-bg: #dfecf0;
  --bs-alert-border-color: #cfe2e9; }
  .alert-info .alert-link {
    color: #2d4c57; }

.alert-warning {
  --bs-alert-color: #805821;
  --bs-alert-bg: #f7e9d7;
  --bs-alert-border-color: #f2dfc3; }
  .alert-warning .alert-link {
    color: #66461a; }

.alert-danger {
  --bs-alert-color: #6f2522;
  --bs-alert-bg: #f1d8d7;
  --bs-alert-border-color: #eac5c4; }
  .alert-danger .alert-link {
    color: #591e1b; }

.alert-light {
  --bs-alert-color: #5f5f5f;
  --bs-alert-bg: #fcfcfc;
  --bs-alert-border-color: #fafafa; }
  .alert-light .alert-link {
    color: #4c4c4c; }

.alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf; }
  .alert-dark .alert-link {
    color: #101214; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.84375rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 2px;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #2a5989;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition); }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height); }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 2px;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #606060;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #2a5989;
  --bs-list-group-active-border-color: #2a5989;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius); }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > .list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg); }
  .list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg); }

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg); }
  .list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color); }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: calc(-1 * var(--bs-list-group-border-width));
      border-top-width: var(--bs-list-group-border-width); }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: calc(-1 * var(--bs-list-group-border-width));
      border-left-width: var(--bs-list-group-border-width); }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 790px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width); }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #193552;
  background-color: #d4dee7; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #193552;
    background-color: #bfc8d0; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #193552;
    border-color: #193552; }

.list-group-item-secondary {
  color: #525252;
  background-color: whitesmoke; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #525252;
    background-color: #dddddd; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #525252;
    border-color: #525252; }

.list-group-item-success {
  color: #235b23;
  background-color: #d8ead8; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #235b23;
    background-color: #c2d3c2; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #235b23;
    border-color: #235b23; }

.list-group-item-info {
  color: #385f6d;
  background-color: #dfecf0; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #385f6d;
    background-color: #c9d4d8; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #385f6d;
    border-color: #385f6d; }

.list-group-item-warning {
  color: #805821;
  background-color: #f7e9d7; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #805821;
    background-color: #ded2c2; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #805821;
    border-color: #805821; }

.list-group-item-danger {
  color: #6f2522;
  background-color: #f1d8d7; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #6f2522;
    background-color: #d9c2c2; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #6f2522;
    border-color: #6f2522; }

.list-group-item-light {
  color: #5f5f5f;
  background-color: #fcfcfc; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #5f5f5f;
    background-color: #e3e3e3; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #5f5f5f;
    border-color: #5f5f5f; }

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #141619;
    background-color: #bebebf; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #141619;
    border-color: #141619; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 2px;
  opacity: 0.5; }
  .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(42, 89, 137, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.9rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 2px;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius); }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: var(--bs-toast-spacing); }

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width)); }
  .toast-header .btn-close {
    margin-right: calc(-.5 * var(--bs-toast-padding-x));
    margin-left: var(--bs-toast-padding-x); }

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word; }

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 4px;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: 3px;
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0; }

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg); }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity); }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius); }
  .modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto; }

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height); }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding); }

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius); }
  .modal-footer > * {
    margin: calc(var(--bs-modal-footer-gap) * .5); }

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto; }
  .modal-sm {
    --bs-modal-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header,
  .modal-fullscreen .modal-footer {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header,
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 789.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header,
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header,
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header,
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header,
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; } }

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.98438rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 2px;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: "Exo 2", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: var(--bs-tooltip-opacity); }
  .tooltip .tooltip-arrow {
    display: block;
    width: var(--bs-tooltip-arrow-width);
    height: var(--bs-tooltip-arrow-height); }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0; }
  .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    top: -1px;
    border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-top-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    right: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-right-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0; }
  .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-bottom-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    left: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-left-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius); }

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.98438rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 4px;
  --bs-popover-inner-border-radius: 3px;
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1.125rem;
  --bs-popover-header-color: #4d4d4d;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #606060;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: "Exo 2", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius); }
  .popover .popover-arrow {
    display: block;
    width: var(--bs-popover-arrow-width);
    height: var(--bs-popover-arrow-height); }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-width: 0; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-top-color: var(--bs-popover-arrow-border); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: var(--bs-popover-border-width);
    border-top-color: var(--bs-popover-bg); }

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-right-color: var(--bs-popover-arrow-border); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: var(--bs-popover-border-width);
    border-right-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-bottom-color: var(--bs-popover-arrow-border); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: var(--bs-popover-border-width);
    border-bottom-color: var(--bs-popover-bg); }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg); }

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-left-color: var(--bs-popover-arrow-border); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: var(--bs-popover-border-width);
    border-left-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color); }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name); }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent; }

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0; }

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s; } }

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075); }

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-sm {
      transition: none; } }

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-sm.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-sm.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-sm.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
      transform: none; }
    .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
      visibility: visible; } }

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-sm .offcanvas-header {
      display: none; }
    .offcanvas-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 789.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 789.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-md {
      transition: none; } }

@media (max-width: 789.98px) {
    .offcanvas-md.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-md.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-md.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-md.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
      transform: none; }
    .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
      visibility: visible; } }

@media (min-width: 790px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-md .offcanvas-header {
      display: none; }
    .offcanvas-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-lg {
      transition: none; } }

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-lg.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-lg.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-lg.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
      transform: none; }
    .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
      visibility: visible; } }

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-lg .offcanvas-header {
      display: none; }
    .offcanvas-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xl {
      transition: none; } }

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
      visibility: visible; } }

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xl .offcanvas-header {
      display: none; }
    .offcanvas-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; } }
  @media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xxl {
      transition: none; } }

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xxl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xxl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xxl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
      visibility: visible; } }

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xxl .offcanvas-header {
      display: none; }
    .offcanvas-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }
  .offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%); }
  .offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%); }
  .offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%); }
  .offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%); }
  .offcanvas.showing, .offcanvas.show:not(.hiding) {
    transform: none; }
  .offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
    visibility: visible; }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x); }
  .offcanvas-header .btn-close {
    padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
    margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
    margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
    margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y)); }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5; }

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5; }
  .placeholder.btn::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(42, 89, 137, var(--bs-bg-opacity, 1)) !important; }

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(205, 205, 205, var(--bs-bg-opacity, 1)) !important; }

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(59, 151, 59, var(--bs-bg-opacity, 1)) !important; }

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(94, 158, 181, var(--bs-bg-opacity, 1)) !important; }

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(213, 147, 55, var(--bs-bg-opacity, 1)) !important; }

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(185, 61, 57, var(--bs-bg-opacity, 1)) !important; }

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(238, 238, 238, var(--bs-bg-opacity, 1)) !important; }

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important; }

.link-primary {
  color: #2a5989 !important; }
  .link-primary:hover, .link-primary:focus {
    color: #22476e !important; }

.link-secondary {
  color: #cdcdcd !important; }
  .link-secondary:hover, .link-secondary:focus {
    color: #d7d7d7 !important; }

.link-success {
  color: #3b973b !important; }
  .link-success:hover, .link-success:focus {
    color: #62ac62 !important; }

.link-info {
  color: #5e9eb5 !important; }
  .link-info:hover, .link-info:focus {
    color: #7eb1c4 !important; }

.link-warning {
  color: #d59337 !important; }
  .link-warning:hover, .link-warning:focus {
    color: #dda95f !important; }

.link-danger {
  color: #b93d39 !important; }
  .link-danger:hover, .link-danger:focus {
    color: #94312e !important; }

.link-light {
  color: #eeeeee !important; }
  .link-light:hover, .link-light:focus {
    color: #f1f1f1 !important; }

.link-dark {
  color: #212529 !important; }
  .link-dark:hover, .link-dark:focus {
    color: #1a1e21 !important; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 790px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important; }

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important; }

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important; }

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important; }

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important; }

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important; }

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important; }

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important; }

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important; }

.border-1 {
  --bs-border-width: 1px; }

.border-2 {
  --bs-border-width: 2px; }

.border-3 {
  --bs-border-width: 3px; }

.border-4 {
  --bs-border-width: 4px; }

.border-5 {
  --bs-border-width: 5px; }

.border-opacity-10 {
  --bs-border-opacity: 0.1; }

.border-opacity-25 {
  --bs-border-opacity: 0.25; }

.border-opacity-50 {
  --bs-border-opacity: 0.5; }

.border-opacity-75 {
  --bs-border-opacity: 0.75; }

.border-opacity-100 {
  --bs-border-opacity: 1; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important; }

.fs-2 {
  font-size: calc(1.31625rem + 0.795vw) !important; }

.fs-3 {
  font-size: calc(1.27688rem + 0.3225vw) !important; }

.fs-4 {
  font-size: calc(1.26rem + 0.12vw) !important; }

.fs-5 {
  font-size: 1.2375rem !important; }

.fs-6 {
  font-size: 1.125rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-semibold {
  font-weight: 600 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: #999 !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: var(--bs-border-radius) !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important; }

.rounded-2 {
  border-radius: var(--bs-border-radius) !important; }

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important; }

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important; }

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important; }

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 790px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important; }
  .fs-2 {
    font-size: 1.9125rem !important; }
  .fs-3 {
    font-size: 1.51875rem !important; }
  .fs-4 {
    font-size: 1.35rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

/*
// FOR OPTIMISED CSS, COMMENT ABOVE LINES AND UNCOMMENT BELOW LINES
// include Bootstrap 5 functions first so we can manipulate colours etc
@import "../../node_modules/bootstrap/scss/functions";

// include any default variable overrides here
@import "variables";
@import "colours";

// include all REQUIRED Bootstrap stylesheets
@import "../../node_modules/bootstrap/scss/variables";
// include any default map overrides here
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";

// optionally include OPTIONAL Bootstrap stylesheets as required
// configuration
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/utilities";
// layout & components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/accordion";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/offcanvas";
@import "../../node_modules/bootstrap/scss/placeholders";
// helpers
@import "../../node_modules/bootstrap/scss/helpers";

// optionally include utilities API last to generate classes based on the SASS map in _utilities.scss
@import "../../node_modules/bootstrap/scss/utilities/api";
*/
/* -------------- SITE SYSTEM 6 THEME  Copyright 2024 Sinclair Design --------------- */
/* -------------------                 aos.scss                   ------------------- */
/* ---------------------------------------------------------------------------------- */
/* --------------------------- CUSTOM SCSS FOR CLIENT ------------------------------- */
/* ---------------------------------------------------------------------------------- */
[data-aos][data-aos][data-aos-duration='50'],
body[data-aos-duration='50'] [data-aos] {
  transition-duration: 50ms; }

[data-aos][data-aos][data-aos-delay='50'],
body[data-aos-delay='50'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='50'].aos-animate,
body[data-aos-delay='50'] [data-aos].aos-animate {
  transition-delay: 50ms; }

[data-aos][data-aos][data-aos-duration='100'],
body[data-aos-duration='100'] [data-aos] {
  transition-duration: .1s; }

[data-aos][data-aos][data-aos-delay='100'],
body[data-aos-delay='100'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='100'].aos-animate,
body[data-aos-delay='100'] [data-aos].aos-animate {
  transition-delay: .1s; }

[data-aos][data-aos][data-aos-duration='200'],
body[data-aos-duration='200'] [data-aos] {
  transition-duration: .2s; }

[data-aos][data-aos][data-aos-delay='200'],
body[data-aos-delay='200'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='200'].aos-animate,
body[data-aos-delay='200'] [data-aos].aos-animate {
  transition-delay: .2s; }

[data-aos][data-aos][data-aos-duration='300'],
body[data-aos-duration='300'] [data-aos] {
  transition-duration: .3s; }

[data-aos][data-aos][data-aos-delay='300'],
body[data-aos-delay='300'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='300'].aos-animate,
body[data-aos-delay='300'] [data-aos].aos-animate {
  transition-delay: .3s; }

[data-aos][data-aos][data-aos-duration='400'],
body[data-aos-duration='400'] [data-aos] {
  transition-duration: .4s; }

[data-aos][data-aos][data-aos-delay='400'],
body[data-aos-delay='400'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='400'].aos-animate,
body[data-aos-delay='400'] [data-aos].aos-animate {
  transition-delay: .4s; }

[data-aos][data-aos][data-aos-duration='500'],
body[data-aos-duration='500'] [data-aos] {
  transition-duration: .5s; }

[data-aos][data-aos][data-aos-delay='500'],
body[data-aos-delay='500'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='500'].aos-animate,
body[data-aos-delay='500'] [data-aos].aos-animate {
  transition-delay: .5s; }

[data-aos][data-aos][data-aos-duration='600'],
body[data-aos-duration='600'] [data-aos] {
  transition-duration: .6s; }

[data-aos][data-aos][data-aos-delay='600'],
body[data-aos-delay='600'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='600'].aos-animate,
body[data-aos-delay='600'] [data-aos].aos-animate {
  transition-delay: .6s; }

[data-aos][data-aos][data-aos-duration='700'],
body[data-aos-duration='700'] [data-aos] {
  transition-duration: .7s; }

[data-aos][data-aos][data-aos-delay='700'],
body[data-aos-delay='700'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='700'].aos-animate,
body[data-aos-delay='700'] [data-aos].aos-animate {
  transition-delay: .7s; }

[data-aos][data-aos][data-aos-duration='800'],
body[data-aos-duration='800'] [data-aos] {
  transition-duration: .8s; }

[data-aos][data-aos][data-aos-delay='800'],
body[data-aos-delay='800'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='800'].aos-animate,
body[data-aos-delay='800'] [data-aos].aos-animate {
  transition-delay: .8s; }

[data-aos][data-aos][data-aos-duration='900'],
body[data-aos-duration='900'] [data-aos] {
  transition-duration: .9s; }

[data-aos][data-aos][data-aos-delay='900'],
body[data-aos-delay='900'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='900'].aos-animate,
body[data-aos-delay='900'] [data-aos].aos-animate {
  transition-delay: .9s; }

[data-aos][data-aos][data-aos-duration='1000'],
body[data-aos-duration='1000'] [data-aos] {
  transition-duration: 1s; }

[data-aos][data-aos][data-aos-delay='1000'],
body[data-aos-delay='1000'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1000'].aos-animate,
body[data-aos-delay='1000'] [data-aos].aos-animate {
  transition-delay: 1s; }

[data-aos][data-aos][data-aos-duration='1100'],
body[data-aos-duration='1100'] [data-aos] {
  transition-duration: 1.1s; }

[data-aos][data-aos][data-aos-delay='1100'],
body[data-aos-delay='1100'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1100'].aos-animate,
body[data-aos-delay='1100'] [data-aos].aos-animate {
  transition-delay: 1.1s; }

[data-aos][data-aos][data-aos-duration='1200'],
body[data-aos-duration='1200'] [data-aos] {
  transition-duration: 1.2s; }

[data-aos][data-aos][data-aos-delay='1200'],
body[data-aos-delay='1200'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1200'].aos-animate,
body[data-aos-delay='1200'] [data-aos].aos-animate {
  transition-delay: 1.2s; }

[data-aos][data-aos][data-aos-duration='1300'],
body[data-aos-duration='1300'] [data-aos] {
  transition-duration: 1.3s; }

[data-aos][data-aos][data-aos-delay='1300'],
body[data-aos-delay='1300'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1300'].aos-animate,
body[data-aos-delay='1300'] [data-aos].aos-animate {
  transition-delay: 1.3s; }

[data-aos][data-aos][data-aos-duration='1500'],
body[data-aos-duration='1500'] [data-aos] {
  transition-duration: 1.5s; }

[data-aos][data-aos][data-aos-delay='1500'],
body[data-aos-delay='1500'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1500'].aos-animate,
body[data-aos-delay='1500'] [data-aos].aos-animate {
  transition-delay: 1.5s; }

[data-aos][data-aos][data-aos-duration='1700'],
body[data-aos-duration='1700'] [data-aos] {
  transition-duration: 1.7s; }

[data-aos][data-aos][data-aos-delay='1700'],
body[data-aos-delay='1700'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1700'].aos-animate,
body[data-aos-delay='1700'] [data-aos].aos-animate {
  transition-delay: 1.7s; }

[data-aos][data-aos][data-aos-duration='1800'],
body[data-aos-duration='1800'] [data-aos] {
  transition-duration: 1.8s; }

[data-aos][data-aos][data-aos-delay='1800'],
body[data-aos-delay='1800'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='1800'].aos-animate,
body[data-aos-delay='1800'] [data-aos].aos-animate {
  transition-delay: 1.8s; }

[data-aos][data-aos][data-aos-duration='2000'],
body[data-aos-duration='2000'] [data-aos] {
  transition-duration: 2s; }

[data-aos][data-aos][data-aos-delay='2000'],
body[data-aos-delay='2000'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='2000'].aos-animate,
body[data-aos-delay='2000'] [data-aos].aos-animate {
  transition-delay: 2s; }

[data-aos][data-aos][data-aos-delay='3000'],
body[data-aos-delay='3000'] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay='3000'].aos-animate,
body[data-aos-delay='3000'] [data-aos].aos-animate {
  transition-delay: 3s; }

[data-aos][data-aos][data-aos-easing=linear],
body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

[data-aos][data-aos][data-aos-easing=ease],
body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease; }

[data-aos][data-aos][data-aos-easing=ease-in],
body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in; }

[data-aos][data-aos][data-aos-easing=ease-in-back],
body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

[data-aos][data-aos][data-aos-easing=ease-in-sine],
body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity, transform; }

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translate(0); }

[data-aos=fade-up] {
  transform: translateY(300px); }

[data-aos=fade-down] {
  transform: translateY(-300px); }

[data-aos=fade-right] {
  transform: translate(-300px); }

[data-aos=fade-left] {
  transform: translate(300px); }

[data-aos=fade-up-right] {
  transform: translate(-300px, 300px); }

[data-aos=fade-up-left] {
  transform: translate(300px, 300px); }

[data-aos=fade-down-right] {
  transform: translate(-300px, -300px); }

[data-aos=fade-down-left] {
  transform: translate(300px, -300px); }

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity, transform; }

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translate(0) scale(1); }

[data-aos=zoom-in] {
  transform: scale(0.6); }

[data-aos=zoom-in-up] {
  transform: translateY(300px) scale(0.6); }

[data-aos=zoom-in-down] {
  transform: translateY(-300px) scale(0.6); }

[data-aos=zoom-in-right] {
  transform: translate(-300px) scale(0.6); }

[data-aos=zoom-in-left] {
  transform: translate(300px) scale(0.6); }

[data-aos=zoom-out] {
  transform: scale(1.2); }

[data-aos=zoom-out-up] {
  transform: translateY(300px) scale(1.2); }

[data-aos=zoom-out-down] {
  transform: translateY(-300px) scale(1.2); }

[data-aos=zoom-out-right] {
  transform: translate(-300px) scale(1.2); }

[data-aos=zoom-out-left] {
  transform: translate(300px) scale(1.2); }

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform; }

[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translate(0); }

[data-aos=slide-up] {
  transform: translateY(100%); }

[data-aos=slide-down] {
  transform: translateY(-100%); }

[data-aos=slide-right] {
  transform: translateX(-100%); }

[data-aos=slide-left] {
  transform: translateX(100%); }

[data-aos^=flip][data-aos^=flip] {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg); }

[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0); }

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg); }

[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0); }

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg); }

[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0); }

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg); }

[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0); }

/* -------------- SITE SYSTEM 6 THEME  Copyright 2024 Sinclair Design --------------- */
/* -------------------              branding.scss                 ------------------- */
/* ---------------------------------------------------------------------------------- */
/* --------------------------- CUSTOM SCSS FOR CLIENT ------------------------------- */
/* ---------------------------------------------------------------------------------- */
.brand1 {
  background-color: #4db45b; }

.brand1-light {
  background-color: #afddb5; }

.brand1-lighter {
  background-color: #cfebd3; }

.brand1-very-light {
  background-color: #e4f4e6; }

.brand1-dark {
  background-color: #367e40; }

.brand1-darker {
  background-color: #275a2e; }

.brand1-border {
  background-color: #b6c4b8; }

.brand2 {
  background-color: #5e739c; }

.brand2-light {
  background-color: #b7c0d2; }

.brand2-lighter {
  background-color: #dce0e9; }

.brand2-very-light {
  background-color: #e7eaf0; }

.brand2-dark {
  background-color: #42516d; }

.brand2-darker {
  background-color: #2f3a4e; }

.brand2-border {
  background-color: #bcbcbc; }

.brand3 {
  background-color: #cec046; }

.brand3-light {
  background-color: #e9e3ac; }

.brand3-lighter {
  background-color: #f4f1d6; }

.brand3-very-light {
  background-color: #f8f6e3; }

.brand3-dark {
  background-color: #908631; }

.brand3-darker {
  background-color: #676023; }

.brand3-border {
  background-color: #d4d0b1; }

.text-brand1 {
  color: #4db45b; }

.text-brand2 {
  color: #5e739c; }

.text-brand3 {
  color: #cec046; }

.text-white {
  color: #fff; }

.text-black {
  color: #000; }

.btn {
  font-weight: 300; }

.btn-default, .btn-default:hover {
  color: #000;
  border: 1px solid #ccc; }

.btn-default:hover {
  border-color: #555; }

.btn-primary, .btn-primary:hover {
  color: #fff; }

.btn-success, .btn-success:hover {
  color: #fff; }

.btn-info, .btn-info:hover {
  color: #fff; }

.btn-warning, .btn-warning:hover {
  color: #fff; }

.btn-danger, .btn-danger:hover {
  color: #fff; }

body, p, ul, dl, ul.tick-list {
  font-size: 1.0125rem;
  font-family: "Exo 2", Helvetica, Arial, sans-serif; }

h1, .h1, .h1 {
  font-size: 2.025rem;
  font-family: "Exo 2", Helvetica, Arial, sans-serif; }

h2, .h2, .h2 {
  font-size: 1.72125rem;
  font-family: "Exo 2", Helvetica, Arial, sans-serif; }

h3, .h3, .h3 {
  font-size: 1.36688rem;
  font-family: "Exo 2", Helvetica, Arial, sans-serif; }

h4, .h4, .h4, .panel-title {
  font-size: 1.215rem;
  font-family: "Exo 2", Helvetica, Arial, sans-serif; }

.table, .table ul, .pagination, label, .label, .inline-checkboxes, .inline-radios, .popover-content, .tooltip-inner {
  font-size: 0.91125rem; }

small, .small, .small, .small p, .small-text {
  font-size: 16px; }

.small-text p {
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 15px; }

large, .large {
  font-size: 21px; }

@media (min-width: 480px) {
  .table, .table ul, .pagination, label, .label, .inline-checkboxes p, .inline-radios p, .popover-content, .tooltip-inner {
    font-size: 1.06875rem; } }

@media (min-width: 576px) {
  body, p, ul, dl, ul.tick-list {
    font-size: 1.06875rem; }
  h1, .h1, .h1 {
    font-size: 2.1375rem; }
  h2, .h2, .h2 {
    font-size: 1.81687rem; }
  h3, .h3, .h3 {
    font-size: 1.44281rem; }
  h4, .h4, .h4, .panel-title {
    font-size: 1.2825rem; } }

@media (min-width: 790px) {
  body, p, ul, dl, ul.tick-list {
    font-size: 1.125rem; }
  h1, .h1, .h1 {
    font-size: 2.25rem; }
  h2, .h2, .h2 {
    font-size: 1.9125rem; }
  h3, .h3, .h3 {
    font-size: 1.51875rem; }
  h4, .h4, .h4, .panel-title {
    font-size: 1.35rem; } }

body, p, ul, dl, .popover-content {
  font-weight: 300; }

b, strong, .bold, .popover-title,
p b, p strong, dt, th, td.bold {
  font-family: "Exo 2", Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #222; }

label, .label {
  font-family: "Exo 2", Helvetica, Arial, sans-serif;
  font-weight: 500; }

.radio label, .checkbox label, .radio-inline, .checkbox-inline, option {
  font-family: "Exo 2", Helvetica, Arial, sans-serif;
  font-weight: 500; }

h1, .h1, .h1, h2, .h2, .h2, h3, .h3, .h3 {
  margin-top: 25px;
  margin-bottom: 15px; }

h1 em, .h1 em {
  font-size: 1.51875rem;
  font-weight: 300;
  color: #aaa; }

.col-content a b, .col-content a strong {
  color: #900; }

p {
  margin-bottom: 20px; }

footer {
  color: #d5edd9; }

footer a, footer strong, footer b {
  color: #d5edd9; }

footer .social a {
  color: #d5edd9; }

footer a:hover {
  color: #fcff64; }

footer h1, footer .h1, footer h2, footer .h2, footer h3, footer .h3 {
  font-size: 1.51875rem;
  color: #d5edd9;
  padding-bottom: 5px;
  margin: 5px 0; }

.credit {
  color: #d5edd9;
  font-size: 16px; }

.credit a {
  color: #d5edd9; }

a {
  text-decoration: none; }

a:hover {
  text-decoration: underline; }

.btn:hover {
  text-decoration: none; }

.col-content > .col-pad-left-10 > .container:first-of-type > .row > .col-md-12 > h1:first-child, .col-content > .col-pad-left-10 > .container:first-of-type > .row > .col-md-12 > .h1:first-child,
.col-content > .col-pad-left-10 > .container:first-of-type > .row > .col-md-12 > h2:first-child,
.col-content > .col-pad-left-10 > .container:first-of-type > .row > .col-md-12 > .h2:first-child,
.col-content > .col-pad-left-10 > .container:first-of-type > .row > .col-md-12 > h3:first-child,
.col-content > .col-pad-left-10 > .container:first-of-type > .row > .col-md-12 > .h3:first-child,
.col-content > .col-pad-right-10 > .container:first-of-type > .row > .col-md-12 > h1:first-child,
.col-content > .col-pad-right-10 > .container:first-of-type > .row > .col-md-12 > .h1:first-child,
.col-content > .col-pad-right-10 > .container:first-of-type > .row > .col-md-12 > h2:first-child,
.col-content > .col-pad-right-10 > .container:first-of-type > .row > .col-md-12 > .h2:first-child,
.col-content > .col-pad-right-10 > .container:first-of-type > .row > .col-md-12 > h3:first-child,
.col-content > .col-pad-right-10 > .container:first-of-type > .row > .col-md-12 > .h3:first-child,
.col-content > .col-pad-left-10 > h1:first-child,
.col-content > .col-pad-left-10 > .h1:first-child,
.col-content > .col-pad-left-10 > h2:first-child,
.col-content > .col-pad-left-10 > .h2:first-child,
.col-content > .col-pad-left-10 > h3:first-child,
.col-content > .col-pad-left-10 > .h3:first-child,
.col-content > .col-pad-right-10 > h1:first-child,
.col-content > .col-pad-right-10 > .h1:first-child,
.col-content > .col-pad-right-10 > h2:first-child,
.col-content > .col-pad-right-10 > .h2:first-child,
.col-content > .col-pad-right-10 > h3:first-child,
.col-content > .col-pad-right-10 > .h3:first-child,
.col-content > .container:first-of-type > .row > .col-md-12 > h1:first-child,
.col-content > .container:first-of-type > .row > .col-md-12 > .h1:first-child,
.col-content > .container:first-of-type > .row > .col-md-12 > h2:first-child,
.col-content > .container:first-of-type > .row > .col-md-12 > .h2:first-child,
.col-content > .container:first-of-type > .row > .col-md-12 > h3:first-child,
.col-content > .container:first-of-type > .row > .col-md-12 > .h3:first-child,
.col-content > h1:first-child,
.col-content > .h1:first-child,
.col-content > h2:first-child,
.col-content > .h2:first-child,
.col-content > h3:first-child,
.col-content > .h3:first-child,
.col-sidebar > h1:first-child,
.col-sidebar > .h1:first-child,
.col-sidebar > h2:first-child,
.col-sidebar > .h2:first-child,
.col-sidebar > h3:first-child,
.col-sidebar > .h3:first-child,
.ccm-custom-style-container > h1:first-child,
.ccm-custom-style-container > .h1:first-child,
.ccm-custom-style-container > h2:first-child,
.ccm-custom-style-container > .h2:first-child,
.ccm-custom-style-container > h3:first-child,
.ccm-custom-style-container > .h3:first-child {
  margin-top: 0; }

section.mainbody {
  overflow-x: hidden; }

.navbar {
  padding: 0; }

main {
  padding-top: 57px; }

#ccm-page-controls-wrapper > #ccm-toolbar {
  z-index: 1040; }

.footer-top {
  position: relative;
  height: 0;
  background: none; }

footer {
  position: relative;
  padding: 20px 0;
  background-color: #367e40; }

footer .footer-main {
  padding-top: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d5edd9; }

footer .footer-bottom {
  padding-top: 20px;
  padding-bottom: 10px; }

section.topbody {
  margin: 20px 0; }

section.mainbody {
  margin: 20px 0 0 0;
  padding-bottom: 20px;
  position: relative; }

section.bottombody {
  margin: -10px 0 0 0;
  padding-bottom: 20px;
  position: relative;
  background-color: #fff; }

@media (min-width: 790px) {
  main {
    padding-top: 157px; }
  section.topbody {
    margin: 20px 0; }
  section.mainbody {
    margin: 20px 0 0 0;
    padding-bottom: 30px; }
  section.bottombody {
    margin: -20px 0 0 0;
    padding-bottom: 30px; } }

@media (min-width: 992px) {
  main {
    padding-top: 167px; }
  section.topbody {
    margin: 25px 0; }
  section.mainbody {
    margin: 25px 0 0 0;
    padding-bottom: 35px; }
  section.bottombody {
    margin: -25px 0 0 0;
    padding-bottom: 35px; } }

@media (min-width: 1200px) {
  main {
    padding-top: 202px; }
  section.topbody {
    margin: 30px 0; }
  section.mainbody {
    margin: 30px 0 0 0;
    padding-bottom: 40px; }
  section.bottombody {
    margin: -30px 0 0 0;
    padding-bottom: 40px; } }

.extend-width,
.extend-width .ccm-image-slider-container {
  position: relative;
  box-sizing: border-box;
  left: 50%;
  width: 100vw;
  min-width: 100%;
  padding: 0;
  margin-left: -50vw; }

.extend-width > div {
  width: 100%; }

a#skiptocontent {
  position: absolute;
  top: -40px;
  left: 0px;
  opacity: 0;
  padding: 6px 10px;
  color: #fff;
  background: #BF1722;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  border-bottom-right-radius: 8px;
  transition: top 0.3s ease-out;
  z-index: 100; }

a#skiptocontent:focus, a#skiptocontent:active {
  top: 0px;
  opacity: 1; }

html.ccm-toolbar-visible .navbar.fixed-top {
  top: 48px; }

html.ccm-toolbar-visible.ccm-edit-mode #top.ccm-page > .ccm-global-area:nth-of-type(1) {
  margin-top: 250px; }

html.ccm-toolbar-visible.ccm-edit-mode #wrap {
  padding-top: 0; }

.home-main-right p {
  margin-bottom: 11px; }

#wrap section.fixed-position {
  position: fixed;
  top: 57px;
  left: 0;
  right: 0;
  margin: 0 auto; }

#wrap.logged-in section.fixed-position {
  top: 105px; }

html:not(.ccm-edit-mode) .crop-to-middle {
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden; }

html:not(.ccm-edit-mode) .crop-to-middle div {
  height: 330px;
  position: relative;
  width: 100%;
  flex: 0 0 auto; }

html:not(.ccm-edit-mode) .crop-to-middle div img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center; }

#wrap section.move-over-fixed {
  position: relative;
  background: #fff;
  margin-top: 330px;
  padding-top: 20px; }

.home-main-pic {
  margin-top: 0px; }

.home-top-left p, .home-top-right p, .home-top-middle p {
  background: #e9e3ac;
  border-radius: 4px;
  text-align: center;
  margin: 0; }

.home-top-left p a, .home-top-middle p a, .home-top-right p a {
  display: block;
  background: #e9e3ac;
  border-radius: 4px;
  font-size: 1.125rem;
  padding: 5px;
  color: #000;
  font-weight: 500;
  margin: 13px 0; }

.home-top-middle p a, .home-top-right p a {
  margin-top: 0; }

.home-top-left p a:hover, .home-top-middle p a:hover, .home-top-right p a:hover {
  background: #cec046;
  text-decoration: none; }

.home-subtitle {
  text-align: center;
  margin-bottom: 25px; }

.home-subtitle h1, .home-subtitle .h1 {
  background: #5e739c;
  color: #fff;
  padding: 10px;
  margin: 10px 0 0; }

.home-subtitle .selector h2, .home-subtitle .selector .h2 {
  text-align: center;
  background: #ccc;
  padding: 10px; }

.home-subtitle .selector h3, .home-subtitle .selector .h3 {
  text-align: center;
  margin-bottom: 13px; }

.home-subtitle .selector p {
  text-align: center;
  margin-bottom: 10px; }

.home-subtitle .selector img {
  margin: 5px 15px; }

.col-content.home-main .home-phone-logo img.phone-logo {
  padding: 35px 5px 5px 0;
  width: 100%;
  max-width: 350px; }

@media (min-width: 576px) {
  #wrap section.fixed-position {
    top: 57px; }
  #wrap.logged-in section.fixed-position {
    top: 105px; }
  html:not(.ccm-edit-mode) .crop-to-middle div {
    height: 340px; }
  #wrap section.move-over-fixed {
    margin-top: 340px;
    padding-top: 30px; }
  .home-main-pic {
    margin-top: 0; } }

@media (min-width: 790px) {
  html:not(.ccm-edit-mode) .crop-to-middle div {
    height: 340px; }
  #wrap section.move-over-fixed {
    margin-top: 340px;
    padding-top: 30px; }
  #wrap section.fixed-position .home-top {
    height: 100px;
    max-height: 100px;
    margin: 0 auto;
    overflow: hidden; }
  .home-top-left p, .home-top-middle p, .home-top-right p {
    margin-top: 22px; }
  .home-top-left p a, .home-top-middle p a, .home-top-right p a {
    padding: 15px;
    margin: 0; } }

@media (min-width: 992px) {
  html:not(.ccm-edit-mode) .crop-to-middle div {
    height: 320px; }
  #wrap section.move-over-fixed {
    margin-top: 320px;
    padding-top: 35px; }
  #wrap section.fixed-position .home-top {
    height: 110px;
    max-height: 110px;
    margin: 0 auto;
    overflow: hidden; }
  .home-top-left p, .home-top-right p, .home-top-middle p {
    margin-top: 26px; } }

@media (min-width: 1200px) {
  html:not(.ccm-edit-mode) .crop-to-middle div {
    height: 430px; }
  #wrap section.move-over-fixed {
    margin-top: 430px;
    padding-top: 40px; }
  #wrap section.fixed-position .home-top {
    height: 145px;
    max-height: 145px;
    margin: 0 auto;
    overflow: hidden; }
  .home-top-left p, .home-top-right p, .home-top-middle p {
    margin-top: 36px; }
  .home-top-left p a, .home-top-middle p a, .home-top-right p a {
    font-size: 21px;
    padding: 20px; } }

.page-template-home .share-icons hr {
  display: none; }

@media print {
  @page {
    size: A4 portrait;
    margin: 10mm; }
  .noprint, #ccm-toolbar, header, .footer-main,
  .youtubeBlock, .slideshareBlock, .side-nav,
  .button-bar {
    display: none !important; }
  a[href]:after {
    content: none !important; }
  img {
    float: none !important;
    position: static !important;
    max-width: 100% !important; }
  .fixed-position {
    position: relative !important;
    top: 0 !important; }
  .move-over-fixed, .mainbody, footer {
    padding: 0 !important;
    margin: 0 !important; } }

.col-content img, .col-content div:not(.porthole) img, .col-sidebar img, .col-sidebar div:not(.porthole) img {
  max-width: 100%;
  height: auto; }

.blog-header-img > picture > img {
  width: 100%;
  height: auto; }

picture > img.ccm-image-block.img-fluid {
  width: 100%;
  height: auto; }

img.corner0, .corner0 img, img.photo, .photo img {
  border-radius: 0; }

img.corner8, .corner8 img {
  border-radius: 8px; }

img.img-circle, .img-circle img {
  border-radius: 50%; }

img.tilt-r, .tilt-r img {
  transform: rotate(2deg); }

img.tilt-l, .tilt-l img {
  transform: rotate(-2deg); }

img.keyline, .keyline img, img.key, .key img {
  border: #aaa solid 1px; }

img.outline, .outline img {
  outline: #999 solid 1px; }

img.drop, .drop img {
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.25); }

img.clear-md-tb, .clear-md-tb img {
  margin: 20px 0 30px; }

img.clear-sm-tb, .clear-sm-tb img {
  margin: 10px 0 15px; }

img.photo, .photo img {
  padding: 2%;
  box-shadow: 3px 3px 8px 3px rgba(0, 0, 0, 0.3);
  background: #fff;
  border: #ddd solid 1px;
  margin: 10px; }

img.ccm-image-block.photo, .photo img.ccm-image-block {
  margin: 0; }

.full-100, .left-70, .right-70, .mid-70, .left-50, .right-50, .mid-50, .left-30, .right-30, .mid-30, .left-15, .right-15, .mid-15 {
  margin: 0;
  color: red; }

img.full-100, .full-100 img {
  width: 100%;
  margin: 10px 0 10px 0; }

.mid-70 img, .mid-50 img, .mid-30 img, .mid-15 img {
  text-align: center;
  margin: 5px; }

img.left-70, .left-70 img, img.left-50, .left-50 img, img.left-30, .left-30 img, img.left-15, .left-15 img {
  float: left;
  margin: 10px 25px 10px 0; }

img.left-15, .left-15 img {
  margin-right: 15px; }

img.right-70, .right-70 img, img.right-50, .right-50 img, img.right-30, .right-30 img, img.right-15, .right-15 img {
  float: right;
  margin: 10px 0 10px 10px; }

.right picture img, .right img {
  float: right; }

img.mid-30, .mid-30 img, img.left-30, .left-30 img, img.right-30, .right-30 img {
  width: 70%; }

img.mid-15, .mid-15 img, img.left-15, .left-15 img, img.right-15, .right-15 img {
  width: 50%; }

img.mid-30 {
  margin: 5px 15%; }

img.mid-15 {
  margin: 5px 25%; }

@media (min-width: 576px) {
  img.mid-50, .mid-50 img, img.left-50, .left-50 img, img.right-50, .right-50 img {
    width: 70%; }
  img.mid-30, .mid-30 img, img.left-30, .left-30 img, img.right-30, .right-30 img {
    width: 50%; }
  img.mid-15, .mid-15 img, img.left-15, .left-15 img, img.right-15, .right-15 img {
    width: 30%; }
  img.mid-50 {
    margin: 5px 15%; }
  img.mid-30 {
    margin: 5px 25%; }
  img.mid-15 {
    margin: 5px 35%; } }

@media (min-width: 790px) {
  img.mid-70, .mid-70 img, img.left-70, .left-70 img, img.right-70, .right-70 img {
    width: 70%; }
  img.mid-50, .mid-50 img, img.left-50, .left-50 img, img.right-50, .right-50 img {
    width: 50%; }
  img.mid-30, .mid-30 img, img.left-30, .left-30 img, img.right-30, .right-30 img {
    width: 30%; }
  img.mid-15, .mid-15 img, img.left-15, .left-15 img, img.right-15, .right-15 img {
    width: 15%; }
  img.mid-70 {
    margin: 5px 15%; }
  img.mid-50 {
    margin: 5px 25%; }
  img.mid-30 {
    margin: 5px 35%; }
  img.mid-15 {
    margin: 5px 42%; } }

.plus-on-hover, .arrows-on-hover, .search-on-hover {
  position: relative; }

.plus-on-hover a picture::before,
.arrows-on-hover a picture::before,
.plus-on-hover a picture::before {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "\f055";
  font-family: "Font Awesome 5 Free";
  font-size: 50px;
  color: #fff;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  font-weight: 900;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.5s; }

.arrows-on-hover a picture::before {
  content: "\f31e"; }

.search-on-hover a picture::before {
  content: "\f00e"; }

.plus-on-hover a:hover picture::before,
.arrows-on-hover a:hover picture::before,
.search-on-hover a:hover picture::before {
  opacity: 1.0;
  transition: opacity 0.5s; }

.plus-on-hover img {
  margin: 0 auto; }

a img.img-show, a img.img-get, a img.img-link {
  filter: brightness(100%);
  transition: 0.5s; }

a:hover img.img-show, a:hover img.img-get, a:hover img.img-link {
  filter: brightness(50%);
  transition: 0.5s; }

a .img-caption {
  opacity: 0;
  z-index: 1;
  text-align: center;
  transition: 0.5s; }

a .img-caption i {
  font-size: 40px;
  color: #fff; }

a .img-caption p {
  font-size: 1.125rem;
  color: #fff;
  margin: 5px 0; }

a:hover .img-caption {
  opacity: 1;
  transition: 0.5s; }

ul.filters {
  list-style: none;
  margin: 25px 0 0;
  text-align: center; }

ul.filters li {
  display: inline-block; }

ul.filters li a {
  display: block;
  padding: 3px 8px;
  border: 1px transparent solid;
  color: inherit;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.2s; }

ul.filters li.active a {
  border: 1px #555 solid; }

ul.filters li a:hover {
  text-decoration: none;
  background-color: #555 !important;
  color: #fff !important; }

ul.filters.filters-openup li {
  margin-right: 10px; }

.filter-container {
  margin-top: 15px; }

.filtr-item {
  padding: 5px 15px 20px; }

.grey-bg, .colour1-bg, .colour2-bg, .colour3-bg, .success-bg, .highlight-bg, .warning-bg, .danger-bg {
  background: #f3f3f3;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px; }

.colour1-bg {
  background: #e4f4e6; }

.colour2-bg {
  background: #e7eaf0; }

.colour3-bg {
  background: #f8f6e3; }

.success-bg {
  background: #d8ead8; }

.highlight-bg {
  background: #ffa; }

.warning-bg {
  background: #f2dfc3; }

.danger-bg {
  background: #eac5c4; }

.grey-bg p:last-child, .colour1-bg p:last-child, .colour2-bg p:last-child, .colour3-bg p:last-child,
.success-bg p:last-child, .highlight-bg p:last-child, .warning-bg p:last-child, .danger-bg p:last-child {
  margin-bottom: 5px; }

.navbar-ss6 .navbar-nav {
  overflow-y: scroll;
  max-height: calc(100vh - 57px); }

.navbar-ss6 .dropdown-item, .navbar-ss6 .dropdown-item:hover, .navbar-ss6 .dropdown-item:active {
  background-color: inherit; }

.navbar-ss6 .navbar-nav li, .navbar-ss6 .navbar-nav li {
  position: relative; }

.navbar-ss6 .navbar-nav li, .navbar-ss6 .navbar-nav li > a {
  font-size: 18px;
  font-weight: 300;
  text-decoration: none; }

.top .navbar {
  background-color: #42516d;
  height: 57px;
  margin-top: 0; }

.top .navbar .navbar-brand-small {
  height: 57px; }

.navbar-toggler .icon-bar {
  display: block;
  width: 33px;
  height: 2px;
  transition: all 0.2s;
  background-color: #fff; }

.navbar-toggler .icon-bar:nth-of-type(1) {
  transform: rotate(45deg);
  transform-origin: 10% 10%; }

.navbar-toggler .icon-bar:nth-of-type(2) {
  opacity: 0; }

.navbar-toggler .icon-bar:nth-of-type(3) {
  transform: rotate(-45deg);
  transform-origin: 10% 90%; }

.navbar-toggler.collapsed .icon-bar:nth-of-type(1) {
  transform: rotate(0); }

.navbar-toggler.collapsed .icon-bar:nth-of-type(2) {
  opacity: 1; }

.navbar-toggler.collapsed .icon-bar:nth-of-type(3) {
  transform: rotate(0); }

.navbar-toggler .icon-bar + .icon-bar {
  margin-top: 7px; }

.navbar-toggler {
  border: 0; }

.navbar-toggler:focus {
  box-shadow: 0 0 0 0; }

.navbar-brand-small img.logo {
  display: inline-block;
  max-height: 46px;
  margin-top: 6px; }

.navbar-ss6 > .container-md {
  padding: 0 15px;
  position: relative;
  height: 100%;
  align-items: center;
  transition: all 0.8s; }

.navbar-brand {
  opacity: 0;
  position: absolute;
  top: -100px;
  height: 76px;
  transform: translateY(12px);
  padding: 0;
  margin: 0 15px;
  z-index: -1;
  transition: all 0.8s;
  transition-delay: 0.2s; }

.navbar-brand img {
  height: 100%;
  display: block;
  margin: 0 auto; }

.navbar-ss6 .navbar-nav li > a:focus,
.navbar-ss6 .navbar-nav li > a:hover,
.navbar-ss6 .navbar-nav li.active > a,
.navbar-ss6 .navbar-nav li.active > a:focus,
.navbar-ss6 .navbar-nav li.active > a:hover,
.navbar-ss6 .navbar-nav .open .dropdown-menu > .active > a:focus,
.navbar-ss6 .navbar-nav .open .dropdown-menu > .active > a:hover,
.navbar-ss6 .navbar-nav li.open > a,
.navbar-ss6 .navbar-nav li.open > a:focus,
.navbar-ss6 .navbar-nav li.open > a:hover {
  background-color: rgba(0, 0, 0, 0.2); }

.navbar-ss6 .navbar-nav span.fa-home {
  opacity: 0.8; }

.navbar-collapse {
  background-color: #3aa148;
  padding: 0;
  margin: 0 -12px; }

.navbar-ss6 .navbar-nav li.nav-item {
  padding: 0; }

.navbar-ss6 .navbar-nav li.nav-item > a.nav-link {
  color: #fff;
  padding: 8px 10px 8px 15px; }

.navbar-ss6 .navbar-nav > .dropdown > .dropdown-menu.show {
  background-color: #cec046;
  padding: 0;
  margin: 0 15px;
  border-color: #000; }

.navbar-ss6 .navbar-nav > .dropdown > .dropdown-menu > .dropdown-item {
  padding: 0; }

.navbar-ss6 .navbar-nav > .dropdown > .dropdown-menu > .dropdown-item > a.nav-link {
  color: #fff;
  font-weight: 500;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
  padding: 5px 10px 5px 15px; }

.navbar-ss6 .navbar-nav > .dropdown .dropdown > .dropdown-menu.show {
  background-color: #a09002;
  padding: 0;
  margin: 0 15px;
  border-color: #000; }

.navbar-ss6 .navbar-nav > .dropdown .dropdown > .dropdown-menu > .dropdown-item {
  padding: 0; }

.navbar-ss6 .navbar-nav > .dropdown .dropdown > .dropdown-menu > .dropdown-item > a.nav-link {
  color: #fff;
  font-weight: 500;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
  padding: 3px 10px 3px 12px; }

.navbar-ss6 .navbar-nav a.dropdown-toggle:after {
  vertical-align: 1px;
  margin-left: 5px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(255, 255, 255, 0.5); }

.navbar-ss6 .navbar-nav li.search-item a.dropdown-toggle:after {
  display: none; }

.navbar-ss6 .navbar-nav > li.active a.nav-link.active {
  background-color: rgba(255, 255, 255, 0.15);
  border: 1px rgba(255, 255, 255, 0.25) solid;
  border-left: none;
  border-right: none; }

.navbar-ss6 .navbar-nav > li.active .dropdown-menu > li.active > a.nav-link.active {
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px rgba(255, 255, 255, 0.4) solid;
  border-left: none;
  border-right: none; }

.navbar-ss6 .navbar-nav li.dropdown-item .nav-intro {
  color: #000;
  font-weight: 500;
  padding: 3px 0 3px 12px;
  background: rgba(255, 255, 255, 0.15);
  border-top: 3px solid rgba(0, 0, 0, 0.3);
  border-bottom: 3px solid rgba(255, 255, 255, 0.2);
  white-space: nowrap; }

.navbar-ss6 .navbar-nav > li.search-item > .search-wrapper > .dropdown-menu.show {
  padding: 10px 15px;
  margin: 0;
  background-color: #cec046; }

.navbar-ss6 .navbar-nav > li.search-item > .search-wrapper > .dropdown-menu.show > li.dropdown-item {
  padding: 0;
  margin: 0;
  border: 1px solid #333; }

@media (min-width: 790px) {
  .navbar-ss6 .navbar-nav {
    overflow: visible;
    max-height: none; }
  .navbar-ss6 > .container-md {
    align-items: flex-end; }
  .top .navbar.navbar-ss6 {
    height: 157px;
    align-items: flex-end;
    border-bottom: none;
    background: linear-gradient(0deg, #5e739c 57px, #fff 0);
    transition: all 0.5s; }
  .top .navbar.navbar-ss6.scroll {
    height: 57px;
    background: linear-gradient(0deg, #42516d 57px, #fff 0);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    transition: all 0.5s; }
  .top .navbar.navbar-ss6.scroll .navbar-brand {
    opacity: 0;
    top: -200px;
    transition: all 0.7s;
    transition-delay: 0; }
  .navbar-ss6.scroll .navbar-nav > li > a:hover,
  .navbar-ss6.scroll .navbar-nav > li.active > a,
  .navbar-ss6.scroll .navbar-nav > li.active > a:hover {
    background-color: rgba(0, 0, 0, 0.15); }
  .navbar-ss6 .navbar-nav > li.active a.nav-link.active {
    border: none; }
  .navbar-brand-small {
    opacity: 0;
    position: absolute;
    top: 0;
    left: -999px; }
  .navbar-brand {
    opacity: 1;
    top: 0; }
  .navbar-ss6 .navbar-collapse {
    flex-direction: row;
    justify-content: center; }
  .navbar.fixed-top .navbar-nav .dropdown-menu {
    left: auto;
    right: 0; }
  .navbar.fixed-top .navbar-nav .dropdown-menu .dropdown-menu {
    top: 0;
    left: 95%;
    right: auto;
    margin-top: 5px; }
  .navbar-collapse {
    background-color: transparent;
    margin: 0; }
  .navbar-ss6 .navbar-nav li.nav-item > a.nav-link {
    color: #fff;
    padding: 15px 10px 14px;
    border: none; }
  .navbar-ss6 .navbar-nav > .dropdown > .dropdown-menu.show {
    background-color: #3aa148;
    margin: 0; }
  .navbar-ss6 .navbar-nav > .dropdown > .dropdown-menu > .dropdown-item > a.nav-link {
    color: #fff;
    padding: 7px 10px; }
  .navbar-ss6 .navbar-nav > .dropdown .dropdown > .dropdown-menu.show {
    background-color: #369643;
    margin: 5px 0 0; }
  .navbar-ss6 .navbar-nav > .dropdown .dropdown > .dropdown-menu > .dropdown-item > a.nav-link {
    color: #fff;
    padding: 5px 10px; }
  .navbar-ss6 .navbar-nav > .dropdown .dropdown .dropdown > .dropdown-menu.show {
    background-color: #cec046;
    margin: 5px 0 0; }
  .navbar-ss6 .navbar-nav > .dropdown .dropdown .dropdown > .dropdown-menu > .dropdown-item > a.nav-link {
    color: #fff;
    padding: 5px 10px; }
  .navbar-ss6 .navbar-nav a.dropdown-toggle:after {
    margin-left: 3px;
    border-width: 7px; }
  .navbar-ss6 .navbar-nav .dropdown-menu a.dropdown-toggle:after {
    vertical-align: -3px;
    margin-left: 8px;
    border-right: 0;
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    border-left: 8px solid rgba(0, 0, 0, 0.4); }
  .navbar-ss6 .navbar-nav > li.search-item > .search-wrapper > .dropdown-menu.show > li.dropdown-item {
    border-color: #1f5626; }
  .navbar-ss6 .navbar-nav > li.search-item > .search-wrapper > .dropdown-menu.show {
    width: 300px;
    padding: 15px;
    background-color: #3aa148;
    border: 1px solid #333;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.25); }
  .navbar-ss6 .navbar-nav .dropdown .dropdown-menu {
    transform: scaleY(0);
    transition: transform 0.2s;
    transform-origin: left top;
    display: block !important;
    visibility: hidden; }
  .navbar-ss6 .navbar-nav .dropdown .dropdown-menu.show {
    transform: scaleY(1);
    transition: transform 0.5s;
    visibility: visible; } }

@media (min-width: 992px) {
  .top .navbar.navbar-ss6 {
    height: 167px; }
  .navbar-brand {
    height: 80px;
    transform: translateY(15px); }
  .navbar-ss6 .navbar-nav li.nav-item > a.nav-link {
    font-size: 20px;
    padding: 13px 15px 14px; }
  .navbar-ss6 .navbar-nav > .dropdown .dropdown > .dropdown-menu > .dropdown-item > a.nav-link {
    font-size: 19px;
    padding: 5px 15px; }
  .navbar-ss6 .navbar-nav > .dropdown .dropdown .dropdown > .dropdown-menu > .dropdown-item > a.nav-link {
    font-size: 19px;
    padding: 5px 13px; }
  .navbar-ss6 .navbar-nav li.dropdown-item .nav-intro {
    font-size: 19px; } }

@media (min-width: 1200px) {
  .top .navbar.navbar-ss6 {
    height: 202px; }
  .navbar-brand {
    height: 103px;
    transform: translateY(21px); }
  .navbar-ss6 .navbar-nav li.nav-item > a.nav-link {
    font-size: 22px;
    padding: 12px 15px 12px; } }

@media (min-width: 1200px) {
  .navbar-ss6.scroll .navbar-brand-small {
    opacity: 1;
    left: 15px;
    transition-delay: 2s;
    transition: left 1s; }
  .navbar-ss6.scroll .navbar-collapse {
    justify-content: flex-end; } }

@media (max-width: 991.98px) {
  .navbar-ss6 .navbar-nav > li:nth-last-child(2) .dropdown-menu > li .dropdown-menu {
    left: auto;
    right: 97%; }
  .navbar-ss6 .navbar-nav > li:nth-last-child(2) .dropdown-menu > li .dropdown-menu > li .dropdown-menu {
    right: auto;
    left: 95%; } }

@media (min-width: 1200px) {
  .navbar-ss6.scroll .navbar-nav > li .dropdown-menu > li .dropdown-menu {
    left: auto;
    right: 97%; }
  .navbar-ss6.scroll .navbar-nav > li .dropdown-menu > li .dropdown-menu > li .dropdown-menu {
    right: auto;
    left: 95%; } }

@media (max-width: 789.98px) {
  .breadcrumbs-container {
    display: none; } }

.breadcrumbs-container {
  opacity: 0.7; }

.breadcrumbs-container .breadcrumb {
  font-size: 16px;
  color: #999;
  padding: 9px 0 7px;
  background: transparent;
  border-radius: 0;
  margin-bottom: 0; }

.breadcrumbs-container .breadcrumb li a {
  display: inline-block;
  color: #999;
  padding: 0;
  border-radius: 3px;
  transition: all 0.2s; }

.breadcrumbs-container .breadcrumb li a:hover {
  color: #fff;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.6); }

.breadcrumb > li + li:before {
  color: #bbb;
  content: "\232a";
  padding: 0 0 0 8px; }

.breadcrumb > .active {
  color: #777; }

.side-nav {
  background: #cfebd3; }

.side-nav {
  border-radius: 2px;
  margin: 0 0 15px; }

.side-nav.list-group {
  padding-bottom: 10px; }

.side-nav.list-group + .side-nav.list-group {
  margin-top: 30px; }

.side-nav.list-group a:nth-child(2) {
  padding-top: 10px; }

.side-nav .list-group-item h3, .side-nav .list-group-item .h3 {
  font-size: 1.35rem; }

.side-nav .list-group-item h3, .side-nav .list-group-item .h3, .side-nav .list-group-item h4, .side-nav .list-group-item .h4 {
  margin: 0; }

.side-nav .list-group-item:first-child h3, .side-nav .list-group-item:first-child .h3 {
  color: #367e40;
  border: none; }

.side-nav .list-group-item:first-child {
  padding: 15px 15px 10px;
  background: rgba(0, 0, 0, 0.1);
  border-bottom: 1px #367e40 solid; }

.side-nav .list-group-item {
  font-size: 17px; }

.side-nav .list-group-item, .side-nav .list-group-item:focus {
  padding: 5px 15px;
  background: transparent;
  border: 0;
  color: #777;
  transition: all 0.5s; }

.side-nav .list-group-item.active, .side-nav .list-group-item.active:hover {
  color: #fff;
  padding-left: 15px;
  background: #4db45b; }

.side-nav a.list-group-item:hover {
  color: inherit;
  text-decoration: none;
  padding-left: 25px;
  padding-right: 0;
  background-color: rgba(0, 0, 0, 0.1); }

.side-nav-parent {
  background: #f4f1d6; }

.side-nav-parent .list-group-item:first-child h3, .side-nav-parent .list-group-item:first-child .h3 {
  color: #908631; }

.side-nav-parent .list-group-item:first-child {
  border-bottom: 1px #908631 solid; }

.side-nav-parent .list-group-item.active, .side-nav-parent .list-group-item.active:hover {
  background: #cec046; }

.list-group .list-group-item.nav-intro {
  padding: 5px 15px;
  background: rgba(0, 0, 0, 0.1);
  color: #000;
  font-weight: 500; }

.list-group .list-group-item.nav-intro:hover {
  padding-left: 15px; }

div.extend-width.top-tabs-outer {
  margin-top: -40px;
  margin-bottom: 70px;
  background: #bbbbbb linear-gradient(0deg, #bbbbbb 0%, white 100%); }

div.extend-width.top-tabs-outer > .container {
  border-bottom: 1px solid #888; }

ul.top-tabs-nav {
  justify-content: center;
  padding-top: 30px; }

ul.top-tabs-nav > li {
  margin-right: 4px; }

ul.top-tabs-nav > li > a {
  display: block;
  padding: 6px 8px;
  background: #fff;
  border-radius: 8px 8px 0 0; }

ul.top-tabs-nav > li > a:hover {
  background: #fffacd;
  text-decoration: none; }

ul.top-tabs-nav > li.active > a {
  border: 1px solid #888;
  border-bottom: none;
  margin-bottom: -1px;
  color: #606060;
  font-family: "Exo 2", Helvetica, Arial, sans-serif;
  font-weight: 500; }

ul.top-tabs-nav > li.active > a:hover {
  background: #fff; }

@media (min-width: 576px) {
  ul.top-tabs-nav > li {
    margin-right: 6px; }
  ul.top-tabs-nav > li > a {
    padding: 6px 12px; } }

@media (min-width: 790px) {
  div.extend-width.top-tabs-outer {
    margin-top: -30px; }
  ul.top-tabs-nav > li {
    margin-right: 8px; }
  ul.top-tabs-nav > li > a {
    padding: 6px 20px; } }

@media (min-width: 992px) {
  div.extend-width.top-tabs-outer {
    margin-top: -35px; } }

@media (min-width: 1200px) {
  div.extend-width.top-tabs-outer {
    margin-top: -40px; } }

div .pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover,
div .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  background-color: #4db45b;
  border-color: #4db45b; }

div .pagination > li > a {
  background-color: #eee;
  color: #000;
  border-color: #bbb; }

div .pagination > li > a:hover {
  background-color: #ee9;
  border-color: #cc6;
  color: #000; }

div .pagination > li.disabled > span {
  color: #bbb; }

.bullets ul {
  display: table;
  overflow: hidden;
  list-style: none;
  margin-top: -8px;
  padding: 0; }

.bullets h2 + ul, .bullets .h2 + ul, .bullets h2 + ol, .bullets .h2 + ol {
  margin-top: 0; }

.bullets ul li {
  padding-left: 1.3em;
  margin-bottom: 3px;
  position: relative; }

.bullets ul li:before {
  content: "\2022 \020";
  font-size: 4em;
  color: #cec046;
  position: absolute;
  top: -.68em;
  left: 0; }

.bullets ol {
  display: table;
  overflow: hidden;
  list-style: none;
  margin-top: 0;
  margin-left: 0;
  padding: 0;
  counter-reset: my-counter; }

.bullets ol > li {
  padding-left: 1.8em;
  margin-bottom: 5px;
  position: relative; }

.bullets ol > li:before {
  content: counter(my-counter);
  counter-increment: my-counter;
  font: bold 19px/1.4 "Exo 2", Helvetica, Arial, sans-serif;
  color: #5e739c;
  position: absolute;
  top: 0.05em;
  left: 0; }

.bullets ul > li > ul > li:first-child,
.bullets ul > li > ol > li:first-child,
.bullets ol > li > ul > li:first-child,
.bullets ol > li > ol > li:first-child {
  margin-top: 10px; }

.bullets ul > li > ul > li, .bullets ol > li > ul > li {
  font-size: 90%;
  margin-bottom: 2px; }

@media (min-width: 480px) {
  .bullets ul li {
    padding-left: 1.5em;
    margin-bottom: 5px; } }

.acceditmode {
  padding: 5px 15px;
  background: #c49dec;
  color: #68438f;
  border: 3px solid #68438f;
  margin: 8px 0; }

.accOpen {
  border-bottom: none;
  border-radius: 8px 8px 0 0; }

.accClose {
  border-top: none;
  border-radius: 0 0 8px 8px; }

.accordion-item {
  margin-bottom: 5px; }

.accordion-item > h2, .accordion-item > .h2,
.accordion-item > h3,
.accordion-item > .h3,
.accordion-item > h4,
.accordion-item > .h4,
.accordion-item > p,
.accordion-item > span {
  margin: 0; }

.roy-acc-block .accordion-body {
  background: #f0f0f0;
  color: #606060; }

.accordion-item button.accordion-button {
  font-size: 1.215rem;
  color: #fff;
  padding: 10px 15px;
  transition: all 0.5s;
  opacity: 1;
  box-shadow: none; }

button.accordion-button.grey {
  color: #444;
  background: #ccc; }

button.accordion-button.grey.collapsed {
  background: #ddd; }

button.accordion-button.brand1 {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  background: #45a351; }

button.accordion-button.brand1.collapsed {
  background: #4db45b;
  opacity: 0.7; }

button.accordion-button.brand2 {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  background: #41506c; }

button.accordion-button.brand2.collapsed {
  background: #5e739c;
  opacity: 1; }

button.accordion-button.brand3 {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  background: #9e922a; }

button.accordion-button.brand3.collapsed {
  background: #cec046;
  opacity: 1; }

.accordion.roy-acc-block .brand1,
.accordion.roy-acc-block .brand2,
.accordion.roy-acc-block .brand3 {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

@media (min-width: 576px) {
  .accordion-item button.accordion-button {
    font-size: 1.2825rem; } }

@media (min-width: 790px) {
  .accordion-item button.accordion-button {
    font-size: 1.35rem; } }

.ccm-block-accordion button.accordion-button {
  background-color: #ddd;
  color: #4d4d4d; }

.blog-title {
  margin-bottom: 8px; }

.blog-header-img {
  width: 100%;
  border: #999 solid 1px;
  margin: 0 0 20px 0;
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.25); }

.poster {
  margin: -5px 0 8px;
  color: #999; }

h3.ccm-conversation-message-count, .ccm-conversation-message-count.h3 {
  margin: 40px 0 20px; }

.ccm-block-next-previous h5, .ccm-block-next-previous .h5 {
  margin-bottom: 0; }

h3.leave-message, .leave-message.h3 {
  padding: 15px 0;
  border-top: 10px solid #ddd;
  margin: 30px 0 10px; }

.ccm-conversation-wrapper input {
  font-size: 12px !important; }

.ccm-conversation-attachment-toggle {
  float: none !important; }

div.ccm-conversation-message.message {
  padding-top: 13px;
  border-top: 3px solid #e9e9e9; }

div.ccm-conversation-message.message.ccm-conversation-message-level0 {
  border-top: 5px solid #cfebd3; }

div.ccm-conversation-message div.ccm-conversation-message-controls ul a.btn {
  padding: 3px 10px;
  margin-top: 10px; }

div.ccm-conversation-message div.ccm-conversation-message-controls ul a.ccm-conversation-message-control-icon {
  padding: 0; }

div.ccm-conversation-messages-threaded div.ccm-conversation-message-level5 {
  margin-left: 200px; }

div.ccm-conversation-messages-threaded div.ccm-conversation-message-level6 {
  margin-left: 200px; }

div.ccm-conversation-message div.ccm-conversation-avatar {
  top: 18px; }

.ccm-conversation-message-username {
  color: #4db45b !important; }

.ccm-conversation-load-more-messages button {
  background: #d59337;
  color: #fff;
  font-weight: 500; }

.ccm-conversation-load-more-messages button:hover {
  background: #b37826;
  color: #fff; }

.ccm-conversation-message-controls .btn-sm:hover, .ccm-conversation-message-controls .btn-group-sm > .btn:hover {
  opacity: 1; }

.ccm-conversation-no-messages {
  display: none; }

@media (min-width: 576px) {
  .ccm-conversation-wrapper input {
    padding: 6px 12px !important;
    font-size: 17px !important; } }

@media (min-width: 790px) {
  .ccm-block-next-previous {
    margin-top: -8px; }
  .blog-header-img {
    margin: 5px 0 30px 0; } }

@media (min-width: 992px) {
  .blog-header-img {
    margin: 5px 0 40px 0; } }

/* //piano blog table
table.piano-table th, table.piano-table td { padding: 2px 5px; white-space: nowrap; }
table.piano-table th { font-weight: $body-font-weight; font-style: italic; background-color: #eee; padding: 5px; }
table.piano-table tr { border-bottom: 1px solid #ddd; }
table.piano-table .sound-group { background-color: #d1e0ed; font-weight: $headings-font-weight; font-size: 19px; color: #02437b; padding: 5px 10px; }
table.piano-table td:nth-child(3n) { font-weight: $headings-font-weight; color: #2672b2; }
*/
.grey-bg > .inset-form, .colour1-bg > .inset-form, .colour2-bg > .inset-form, .colour3-bg > .inset-form,
.success-bg > .inset-form, .warning-bg > .inset-form, .danger-bg > .inset-form, .highlight-bg > .inset-form {
  margin: -20px; }

.inset-form {
  padding: 20px 10px; }

.inset-form h3, .inset-form .h3 {
  margin-top: 0; }

.form-group {
  margin-bottom: 15px; }

@media (min-width: 790px) {
  .form-label {
    text-align: right; } }

.ccm-dashboard-express-form .text-muted.small, .miniSurveyView .text-muted.small {
  color: #c88 !important;
  opacity: 0.7;
  font-weight: 300;
  padding-left: 5px; }

.margin-top-0 .g-recaptcha.ecRecaptcha {
  margin-bottom: 0; }

.ccm-dashboard-express-form div.form-group {
  margin-bottom: 10px; }

input::placeholder, input.form-control::placeholder {
  color: #bbb !important;
  font: 300 1.125rem "Exo 2", Helvetica, Arial, sans-serif; }

.col-md-9.col-content .g-recaptcha, .col-lg-9.col-content .g-recaptcha {
  margin: 15px; }

@media (min-width: 790px) {
  .col-md-9.col-content .g-recaptcha, .col-lg-9.col-content .g-recaptcha {
    margin: 5px 0 15px 176px; } }

@media (min-width: 992px) {
  .col-md-9.col-content .g-recaptcha, .col-lg-9.col-content .g-recaptcha {
    margin: 5px 0 15px 233px; } }

.grecaptcha-badge {
  z-index: 1; }

footer .social {
  font-size: 30px;
  text-align: left;
  padding: 0; }

footer .social li {
  display: inline;
  list-style-type: none;
  padding: 0 5px; }

footer .social a:hover {
  text-decoration: none;
  text-shadow: 0 0 5px #fff; }

.social-links.ccm-block-social-links svg {
  height: 24px;
  width: 24px;
  max-height: 24px;
  max-width: 24px;
  margin-bottom: 5px; }

.share-icons #icon-twitter-x {
  color: #bbb;
  height: 20px;
  width: 20px;
  max-height: 20px;
  max-width: 20px;
  margin-bottom: 5px; }

.share-icons #icon-twitter-x:hover {
  color: #ed0202; }

@media (min-width: 576px) {
  footer .social {
    text-align: right; } }

.tight-nav {
  list-style: none;
  padding: 0; }

.tight-nav li {
  padding: 12px 0 12px 0;
  border-top: 1px #aaa solid; }

.tight-nav li a {
  padding-left: 0px;
  border-left: 0px #5e739c solid;
  transition: all 0.5s ease; }

.tight-nav li a:hover {
  text-decoration: none;
  padding-left: 10px;
  border-left: 10px #5e739c solid; }

.tight-nav li.active a {
  color: #fff;
  padding: 2px 5px;
  border-left: none;
  background: rgba(0, 0, 0, 0.2); }

.tight-nav li a.active:hover {
  padding-left: 0;
  border-left: 0; }

footer .tight-nav li a, footer .tight-nav li a:hover {
  border-color: #fcff64; }

@media (min-width: 576px) {
  .tight-nav li {
    padding: 0 0 3px 0;
    border-top: none; } }

.mfp-image-holder .mfp-close {
  font-size: 50px;
  opacity: 1; }

.mfp-content .mfp-bottom-bar {
  padding: 10px 20px;
  margin-top: -25px;
  background-color: #666;
  min-height: 38px; }

.mfp-bottom-bar .mfp-title {
  text-align: center;
  color: #fff; }

.mfp-bottom-bar .mfp-title .ms-2 {
  margin-left: 20px !important;
  padding: 5px 10px;
  background-color: #aaa; }

.mfp-bottom-bar .mfp-counter {
  right: 12px;
  top: 10px; }

.ig-parent {
  position: relative;
  border: 1px solid transparent; }

.ig-parent .ig-overlay {
  z-index: 1;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.5s;
  left: 0;
  right: 0; }

.ig-parent:hover .ig-overlay {
  opacity: 1.0; }

.ig-image {
  text-align: center; }

.ig-overlay-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 21px;
  color: #fff;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6); }

.ig-overlay-text p {
  margin: 5px 0; }

.col-content div.ccm-block-feature-item, div.ccm-block-feature-link {
  margin: 20px 0; }

.feature {
  display: block;
  margin-top: 20px; }

div.ccm-block-feature-item p,
div.ccm-block-feature-item h4,
div.ccm-block-feature-item .h4,
div.ccm-block-feature-item h3,
div.ccm-block-feature-item .h3,
div.ccm-block-feature-item h2,
div.ccm-block-feature-item .h2 {
  margin-left: 30px;
  margin-top: 0; }

div.ccm-block-feature-link h4, div.ccm-block-feature-link .h4,
div.ccm-block-feature-link h3,
div.ccm-block-feature-link .h3,
div.ccm-block-feature-link h2,
div.ccm-block-feature-link .h2 {
  margin-top: 0; }

.feature img {
  float: left;
  margin: 5px 20px 5px 0; }

.feature .ig-overlay {
  margin: -10px -10px 0 -10px;
  background-color: rgba(0, 0, 0, 0.3); }

a.feature, a.feature strong, a.feature b {
  color: #606060; }

a.feature:hover {
  color: #606060;
  text-decoration: none; }

.feature .feature-text p {
  margin-bottom: 10px; }

.feature .feature-icon {
  position: absolute;
  bottom: 0;
  right: 20px;
  font-size: 110px;
  color: rgba(0, 0, 0, 0.1);
  z-index: 1; }

.feature-scroll .feature-link .overlay {
  margin: -10px -10px 0 -10px;
  background-color: rgba(0, 0, 0, 0.3); }

.feature-scroll .feature-link:hover {
  color: inherit;
  text-decoration: none; }

.feature-scroll .feature-link:hover .overlay {
  opacity: 1.0; }

.feature-scroll {
  position: relative;
  min-height: 120px;
  margin: 10px 0 15px;
  z-index: 2;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px; }

.feature-scroll .overlay {
  z-index: 1;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  color: #fff;
  transition: opacity 0.5s; }

section:not(.home-mainbody) .feature-link .feature-scroll .overlay {
  margin: -10px -10px 10px; }

.feature-scroll .overlay:before {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle; }

.feature-scroll .overlay .overlay-name {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  transform: scale(0.3, 0.3);
  transition: transform 1s;
  width: 90%; }

.feature-scroll .feature-link:hover .overlay .overlay-name {
  transform: scale(1, 1); }

.feature-scroll .overlay-name i {
  color: #fff;
  font-size: 50px;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6); }

.feature-scroll .overlay-name p {
  margin: 0; }

.feature-scroll .img-container {
  float: left;
  margin: 3px 20px 5px 0;
  text-align: center; }

.feature-scroll .img-container img {
  margin-bottom: 8px; }

@media (min-width: 576px) {
  .feature-scroll .img-container img {
    margin-bottom: 0; } }

.feature-scroll .img-container.float-sm-end {
  float: right;
  margin: 3px 0 5px 20px; }

.feature-scroll h4, .feature-scroll .h4 {
  color: #4d4d4d;
  margin: 4px 0 7px; }

.feature-scroll p {
  color: #606060;
  text-decoration: none !important;
  z-index: 2;
  margin: 0; }

.features-intro-line {
  font-style: italic;
  color: #999;
  margin: -10px 0 15px; }

@media (min-width: 790px) {
  .home-mainbody .feature-scroll {
    padding-right: 30px; }
  .features-intro-line {
    margin: -10px 0 25px; } }

.feature-scroll-450 .img-container img {
  border: 1px solid #777; }

.feature-scroll-450 .img-container {
  position: relative; }

.feature-scroll-450 .img-container,
.feature-scroll-550 .img-container {
  margin: 3px 35px 5px 0; }

.feature-scroll-450 .img-container.float-sm-end,
.feature-scroll-550 .img-container.float-sm-end {
  margin: 3px 0 5px 35px; }

.feature-scroll-450 {
  border-bottom: 2px solid #d2d2d2;
  padding-top: 20px; }

.feature-scroll-450 p {
  margin-bottom: 12px; }

@media (min-width: 992px) {
  .feature-scroll-450, .feature-scroll-550 {
    padding-bottom: 25px; } }

@media (max-width: 991.98px) {
  .feature-scroll.feature-scroll-450 .img-container {
    float: none;
    margin: 3px auto 5px; } }

@media (max-width: 1199.98px) {
  .feature-scroll.feature-scroll-550 .img-container {
    float: none;
    margin: 3px auto 5px; } }

@media (max-width: 991.98px) {
  .feature-scroll.feature-scroll-450 .img-container,
  .feature-scroll.feature-scroll-550 .img-container img {
    margin-bottom: 15px; } }

.scroll-gallery {
  margin-bottom: 20px; }

.scroll-gallery h4, .scroll-gallery .h4, .scroll-gallery h3, .scroll-gallery .h3 {
  margin-top: 0; }

.scroll-gallery .scroll-gallery-item {
  margin-top: -10px; }

@media (min-width: 576px) {
  .scroll-gallery .col-sm-6 > h4, .scroll-gallery .col-sm-6 > .h4, .scroll-gallery .col-sm-6 > h3, .scroll-gallery .col-sm-6 > .h3 {
    margin-top: -5px; }
  .scroll-gallery .col-sm-12 > h4 .scroll-gallery .col-sm-12 > h3, .scroll-gallery .col-sm-12 > .h4 .scroll-gallery .col-sm-12 > h3, .scroll-gallery .col-sm-12 > h4 .scroll-gallery .col-sm-12 > .h3, .scroll-gallery .col-sm-12 > .h4 .scroll-gallery .col-sm-12 > .h3 {
    margin-top: 0; } }

@media (min-width: 790px) {
  .scroll-gallery h4, .scroll-gallery .h4, .scroll-gallery h3, .scroll-gallery .h3 {
    margin-top: 0; }
  .scroll-gallery .col-sm-12 > h4, .scroll-gallery .col-sm-12 > .h4, .scroll-gallery .col-sm-12:not(.col-md-12) > h3, .scroll-gallery .col-sm-12:not(.col-md-12) > .h3 {
    margin-top: -5px; } }

@media (min-width: 1200px) {
  .col-content .scroll-gallery .scroll-gallery-item {
    margin-top: 15px; }
  .col-content .scroll-gallery {
    margin-bottom: 30px; } }

@media (min-width: 1400px) {
  .col-content .scroll-gallery .scroll-gallery-item {
    margin-top: 20px; }
  .col-content .scroll-gallery {
    margin-bottom: 40px; } }

li.map1 {
  font-weight: 500; }

.search-box {
  background-color: #4db45b;
  margin-bottom: 25px; }

.search-box button {
  background-color: #cfebd3; }

.http-error {
  text-align: center; }

.http-error p {
  font-size: 18px; }

.http-error .http-error-number {
  font-size: 60px;
  line-height: 120%;
  font-family: "Exo 2", Helvetica, Arial, sans-serif;
  font-weight: 500; }

.http-error .icon-image {
  font-size: 70px; }

@media (min-width: 576px) {
  .http-error {
    margin-bottom: 50px; }
  .http-error p {
    font-size: 22px; }
  .http-error .http-error-number {
    font-size: 130px;
    margin-bottom: 20px; }
  .http-error .icon-image {
    font-size: 150px; } }

div.ccm-block-testimonial-paragraph .fa-quote-left, div.ccm-block-testimonial-paragraph .fa-quote-right {
  color: #b7c0d2; }

blockquote {
  border-left: 10px solid #afddb5;
  background: #ebf7ed; }

div div.ccm-block-calendar-event-list-event-date {
  border: 1px solid #333;
  border-radius: 3px; }

div div.ccm-block-calendar-event-list-event-date span:first-child {
  background-color: #900; }

/*
.news .ccm-conversation-message-body ul { display: table; overflow: hidden; list-style: none; margin-top: -8px; padding: 0; }
.news .ccm-conversation-message-body ul li { padding-left: 1.3em; margin-bottom: 3px; position: relative; }
.news .ccm-conversation-message-body ul li:before { content: "\2022 \020"; font-size: 3em; color: $brand1; position: absolute; top: -.44em; left: 0; }
*/
/*
// utility links block
.store-utility-links { background: $cream; padding: 10px 15px; margin-bottom: 10px; }
.store-cart-empty .store-cart-link { display: none; }
.store-items { float: left; position: relative; }
.store-items .fa-shopping-basket { margin-left: 5px; color: #d4a2a2; }
.store-items-counter { position: absolute; top: 10px; left: 24px; display: block; height: 26px; min-width: 26px;
    text-align: center; color: #fff; background: #bf0b24; border-radius: 26px; line-height: 24px; }
.counter-zero { display: none; }
.store-items-in-cart { display: block; }
.store-book-link, .store-cart-link, .store-total-cart-amount { float: left; padding: 6px 12px; margin: 10px 10px 10px 0; }
.store-book-link { float: right; }
// product page
.store-product h1.store-product-name { margin: 40px 0 10px; }
.store-product .store-sale-price, .store-product .store-original-price, .store-product .store-list-price { padding: 5px 15px; background: #eee; text-decoration: none; }
.store-product label { color: darken($brand1, 5%); }
.store-product-primary-image img { box-shadow: $picture-shadow; margin-bottom: 20px; }
.store-product-additional-image { padding: 15px 10px !important; }
// basket modal and page
.store-whiteout .store-cart-modal { padding: 25px; width: 95%; top: 5%; max-height: 90%; }
.store-cart-modal h2 { color: $brand2; }
.store-cart-modal .store-qty-container, .store-cart-page .store-qty-container { min-width: 0; }
.store-cart-page h1, .store-order-complete-page h1 { margin-top: 40px; }
// checkout
.store-checkout-page label { color: darken($brand1, 5%); }
.scrollable { margin: 0; padding: 0; overflow: scroll; max-height: 160px; }
ul.store-checkout-totals-line-items li { background: #eee; border: none; text-align: right; }
div.store-whiteout { background: rgba(0, 0, 0, 0.5); }
@include media-breakpoint-up(md) {
    .store-utility-links { margin-bottom: 0; }
}
*/
.ccm-block-calendar-wrapper {
  margin-bottom: 30px; }

.fc-head, .fc-list-heading td {
  background: #eee !important; }

.fc-body, .fc-scroller, .fc-list-empty {
  background: #f7f7f7 !important; }

.fc .fc-toolbar button {
  padding: 0 7px; }

.fc-unthemed td.fc-today {
  background: #fafdc6; }

.fc-toolbar h2, .fc-toolbar .h2 {
  color: #b3b3b3;
  font-style: normal;
  font-size: 27px; }

.ccm-block-calendar-event-dialog-details > h3, .ccm-block-calendar-event-dialog-details > .h3 {
  color: #bf0213 !important;
  margin-bottom: 10px !important; }

div.ccm-block-calendar-dialog-event-time {
  color: #fff !important;
  margin-bottom: 20px !important;
  background: #aaa;
  padding: 2px 10px; }

.ccm-block-calendar-event-dialog-details > div > .btn-primary {
  margin-bottom: 10px; }

.fc-day-top a {
  color: #606060; }

.fc-day-top a:hover {
  color: #ed0202; }

.events.inset-1, .events.inset-2, .events.inset-3, .events.inset-4, .events.inset-5 {
  padding: 15px;
  border-radius: 2px; }

.events.inset-1 {
  border: 1px #bbb solid;
  border-radius: 5px; }

.events.inset-2 {
  background: #f3f3f3; }

.events.inset-3 {
  background: #eff8f0; }

.events.inset-4 {
  background: #ffffdd; }

.events.inset-5 {
  background: #f5e2e1; }

.events.inset-0 .title, .events.inset-1 .title, .events.inset-2 .title, .events.inset-3 .title, .events.inset-4 .title, .events.inset-5 .title {
  display: block;
  text-align: center;
  padding: 15px 0 12px;
  margin: -15px -15px 0 -15px;
  background-color: rgba(0, 0, 0, 0.1);
  border-bottom: 2px dotted #bbb;
  border-radius: 2px 2px 0 0; }

.events.inset-0 .title {
  text-align: left;
  padding-bottom: 20px;
  background-color: inherit;
  margin: 0; }

.events li h4, .events li .h4 {
  font-style: normal;
  margin: 4px 0; }

.events ul {
  list-style: none;
  padding: 0;
  margin: 0; }

.events li {
  display: block;
  width: 100%;
  color: inherit;
  text-decoration: none;
  padding: 15px 0 12px 0;
  margin: 0;
  border-bottom: 2px dotted #bbb; }

.notes, .notes b {
  color: #888; }

.fa-grey {
  color: #aaa;
  margin-right: 7px; }

.text-grey {
  color: #999; }

.li-text {
  margin-left: 8.2em; }

.ccm-block-fields h4, .ccm-block-fields .h4 {
  font-style: normal; }

h4 i, .h4 i {
  font-size: 16px;
  font-style: normal;
  color: #aaa; }

time.cal-icon {
  float: left;
  position: relative;
  width: 7em;
  height: 6.6em;
  font-size: 1em;
  background-color: #fff;
  margin: 0;
  border-radius: 0.6em;
  box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 3px 0 #bdbdbd, 0 4px 0 #fff, 0 5px 0 #bdbdbd, 0 0 0 1px #bdbdbd;
  overflow: hidden; }

time.cal-icon * {
  display: block;
  width: 100%;
  font-size: 1em;
  font-weight: 500;
  font-style: normal;
  text-align: center; }

time.cal-icon strong {
  position: absolute;
  top: 0;
  padding: 0.2em 0;
  color: #fff;
  border-bottom: 2px dashed rgba(0, 0, 0, 0.3);
  border-radius: 0.6em 0.6em 0 0; }

time.cal-icon em {
  position: absolute;
  bottom: 0.2em; }

time.cal-icon span {
  width: 100%;
  font-size: 2.8em;
  letter-spacing: -0.05em;
  padding-top: 0.6em;
  color: #333; }

time.cal-icon-brand1 strong {
  background-color: #85af8b;
  box-shadow: 0 2px 0 #85af8b; }

time.cal-icon-brand1 em {
  color: #85af8b; }

time.cal-icon-brand2 strong {
  background-color: #91959c;
  box-shadow: 0 2px 0 #91959c; }

time.cal-icon-brand2 em {
  color: #91959c; }

time.cal-icon-red strong {
  background-color: #c82c1a;
  box-shadow: 0 2px 0 #c82c1a; }

time.cal-icon-red em {
  color: #c82c1a; }

time.cal-icon-blue strong {
  background-color: #3f73a8;
  box-shadow: 0 2px 0 #3f73a8; }

time.cal-icon-blue em {
  color: #3f73a8; }

time.cal-icon-gold strong {
  background-color: #c2a830;
  box-shadow: 0 2px 0 #c2a830; }

time.cal-icon-gold em {
  color: #c2a830; }

time.cal-icon-green strong {
  background-color: #42ad35;
  box-shadow: 0 2px 0 #42ad35; }

time.cal-icon-green em {
  color: #42ad35; }

.youtubeBlock,
.slideshareBlock,
.ccm-image-slider-container,
a.overlay-parent > picture > img.ccm-image-block.img-responsive,
div:not(.full-width-banner):not(.blog-header-img) > picture > img.ccm-image-block.img-responsive {
  margin-bottom: 20px; }

.ccm-image-slider .ccm-image-slider-text {
  padding-top: 0; }

.ccm-image-slider .rslides_tabs {
  padding-top: 10px; }

.button-bar {
  margin-top: 10px;
  margin-bottom: 10px; }

.col-sidebar > .button-bar:first-child {
  margin-top: 0;
  margin-bottom: 30px; }

@media (min-width: 992px) {
  .col-sidebar .button-bar .btn-group {
    width: 100%;
    table-layout: fixed;
    border-collapse: separate; } }

.share-icons .list-inline {
  text-align: center; }

.share-icons .fab, .share-icons .fas {
  font-size: 140%;
  color: #bbbbbb; }

.share-icons .fab:hover, .share-icons .fas:hover {
  color: #900; }

.share-icons .list-inline > li {
  display: inline-block;
  padding: 0 4px; }

@media (min-width: 790px) {
  .share-icons .fab, .share-icons .fas {
    font-size: 120%; }
  .share-icons .list-inline > li {
    padding: 0 2px; } }

@media (min-width: 1200px) {
  .share-icons .fab, .share-icons .fas {
    font-size: 140%; } }

@media (max-width: 789.98px) {
  .fc-toolbar .fc-center h2, .fc-toolbar .fc-center .h2 {
    margin-top: 25px; } }

.ccm-block-page-list-title {
  font-size: 1.44281rem;
  font-family: "Exo 2", Helvetica, Arial, sans-serif;
  margin: 15px 0 5px; }

.htContextMenu.handsontable table.htCore tr > td {
  font-size: 16px; }

.table_display .table-bordered > thead > tr > td, .table_display .table-bordered > thead > tr > th {
  border-bottom-width: 2px; }

.table_display tr > td.highlighted, .handsontable table.htCore td.highlighted {
  background: #ffa; }

hr {
  margin-top: 27px;
  margin-bottom: 27px;
  border-top: 1px solid #aaa; }

@media (max-width: 575.98px) {
  .gm-style-iw {
    min-width: 350px !important; } }

.space-5 {
  width: 5vw;
  display: inline-block;
  visibility: hidden; }

.nav-search-input {
  background: #eee; }

.no-margins {
  margin: 0 !important; }

.no-top-margin {
  margin-top: 0 !important; }

.no-bottom-margin {
  margin-bottom: 0 !important; }

.no-padding {
  padding: 0 !important; }

.no-border {
  border: none !important; }

.margin-bottom-0 {
  margin-bottom: 0 !important; }

.margin-bottom-10 {
  margin-bottom: 10px !important; }

.margin-bottom-20 {
  margin-bottom: 20px !important; }

.margin-bottom-30 {
  margin-bottom: 30px !important; }

.margin-bottom-40 {
  margin-bottom: 40px !important; }

.margin-bottom-50 {
  margin-bottom: 50px !important; }

.margin-top-0 {
  margin-top: 0 !important; }

.margin-top-10 {
  margin-top: 10px !important; }

.margin-top-20 {
  margin-top: 20px !important; }

.margin-top-30 {
  margin-top: 30px !important; }

.margin-top-40 {
  margin-top: 40px !important; }

.margin-top-50 {
  margin-top: 50px !important; }

@media (max-width: 500px) {
  .margin-bottom-30 {
    margin-bottom: 20px !important; } }

@media (min-width: 576px) {
  .col-pad-left-10 {
    padding-left: 10px !important; }
  .col-pad-right-10 {
    padding-right: 10px !important; } }

.margin-right-10 {
  margin-right: 10px !important; }

.centre, .center {
  text-align: center !important; }

.tint-50 {
  opacity: .5; }

.hide, .hide-me {
  display: none; }

.nowrap {
  white-space: nowrap; }

.fa-lrg {
  font-size: 150%; }

.ccm-block-file .btn {
  margin: 0 10px 10px 0; }

.red, .red b {
  color: #be0703; }

.green, .green b {
  color: #390; }

.blue, .blue b {
  color: #1184d6; }

.grey, .grey b {
  color: #aaa; }

hr + h2 + h2 + p, hr + .h2 + h2 + p, hr + h2 + .h2 + p, hr + .h2 + .h2 + p {
  margin-top: -15px;
  margin-bottom: 25px; }

.no-styling {
  outline: none !important;
  box-shadow: none !important; }

div.ccm-notifications-box {
  z-index: 1030; }

.navbar-fixed-bottom, .navbar-fixed-top {
  z-index: 30; }

.ui-dialog-buttonpane .btn.btn-primary {
  background-color: #3b973b;
  border-color: #348534; }

.ui-dialog-buttonpane .btn.btn-primary:hover {
  background-color: #2d722d; }

html.ccm-toolbar-visible .ui-dialog-buttonpane {
  background-color: #d8e4ed; }

.ccm-ui div p, .ccm-ui div ul {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px; }
